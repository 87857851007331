import {
  TbAddressBook,
  TbBarcode,
  TbCar,
  TbChartLine,
  TbFileInvoice,
  TbHomeDollar,
  TbInfoCircle,
  TbLayoutDashboard,
  TbPackage,
  TbUser,
  TbUserExclamation,
  TbUsers,
  TbUsersGroup,
} from "react-icons/tb";
import * as wi from "react-icons/wi";
import { IoIosTimer } from "react-icons/io";

export const menuAdmin = [
  {
    isTitle: false,
    tague: "dashboard",
    isMultiple: false,
    libelle: "Dashboard",
    lien: "/admin/dash",
    icon: (
      <TbLayoutDashboard className={"h-4 w-4"} />
    ),
  },
  {
    isTitle: true,
    libelle: "Services",
    color: "#098721",
  },
  {
    isTitle: false,
    tague: "clients",
    isMultiple: false,
    libelle: "Clients",
    lien: "/admin/dash/clients",
    icon: (

      <TbUsersGroup className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: false,
    tague: "centre-facturation",
    isMultiple: false,
    libelle: "Centres de facturation",
    lien: "/admin/dash/centre-facturation",
    icon: (

      <TbHomeDollar className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: false,
    tague: "utilisateurs",
    isMultiple: false,
    libelle: "Utilisateurs",
    lien: "/admin/dash/utilisateurs",
    icon: (

      <TbUsersGroup className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: false,
    tague: "contacts",
    isMultiple: false,
    libelle: "Contacts",
    lien: "/admin/dash/contacts",
    icon: (

      <TbAddressBook className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: false,
    tague: "dashboard",
    isMultiple: false,
    libelle: "Fournisseurs",
    lien: "/admin/dash/soustraitants",
    icon: (

      <TbUser className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: false,
    tague: "historiques",
    isMultiple: false,
    libelle: "Le 24 Heures",
    lien: "/admin/dash/historiques",
    icon: (

      <IoIosTimer className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: true,
    libelle: "Gestion facture",
    color: "#222",
  },
  {
    isTitle: false,
    tague: "fuel",
    isMultiple: false,
    libelle: "Fuels",
    lien: "/admin/dash/facture/fuel",
    icon: (

      <wi.WiRaindrop className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: false,
    tague: "code-facturation",
    isMultiple: false,
    libelle: "Codes de facturation",
    lien: "/admin/dash/facture/code-facturation",
    icon: (

      <TbBarcode className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: true,
    libelle: "Statistiques",
    color: "#4361ee",
  },
  {
    isTitle: false,
    tague: "expeditions",
    isMultiple: false,
    libelle: "Courses",
    lien: "/admin/dash/statiques/expeditions",
    icon: (

      <TbPackage className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: false,
    tague: "freight",
    isMultiple: false,
    libelle: "Freight",
    lien: "/admin/dash/statiques/freight",
    icon: (

      <TbChartLine className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: true,
    libelle: "Configuration / Gestion",
    color: "#ff595e",
  },
  {
    isTitle: false,
    tague: "trackinh-template",
    isMultiple: false,
    libelle: "Tracking templates",
    lien: "/admin/dash/tracking-template",
    icon: (

      <TbFileInvoice className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: false,
    tague: "transport-types",
    isMultiple: false,
    libelle: "Type de transports",
    lien: "/admin/dash/transport-types",
    icon: (

      <wi.WiRaindrop className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: false,
    tague: "vehicules",
    isMultiple: false,
    libelle: "Véhicules",
    lien: "/admin/dash/vehicules",
    icon: (

      <TbCar className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: false,
    tague: "salaries",
    isMultiple: false,
    libelle: "Salariés",
    lien: "/admin/dash/salaries",
    icon: (

      <TbUser className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: false,
    tague: "administrateurs",
    isMultiple: false,
    libelle: "Admin",
    lien: "/admin/dash/administrateurs",
    icon: (

      <TbUserExclamation className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: false,
    tague: "ops",
    isMultiple: false,
    libelle: "OPS",
    lien: "/admin/dash/ops",
    icon: (

      <TbUsers className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: false,
    tague: "chauffeurs",
    isMultiple: false,
    libelle: "Chauffeurs",
    lien: "/admin/dash/chauffeurs",
    icon: (

      <TbUser className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: false,
    tague: "information",
    isMultiple: false,
    libelle: "Information",
    lien: "/admin/dash/information",
    icon: (

      <TbInfoCircle className={"h-4 w-4"} />

    ),
  },
  {
    isTitle: false,
    tague: "emballages",
    isMultiple: true,
    libelle: "Emballages",
    // lien: "/admin/dash/emballages",
    icon: (

      <TbPackage className={"h-4 w-4"} />

    ),
    liens: [
      {
        libelle: "Types d'emballages",
        lien: "/admin/dash/emballages",
        tague: "types-emballages",
      },
      {
        libelle: "Capacités d'emballages",
        lien: "/admin/dash/capacity",
        tague: "capacity-emballages",
      },
      {
        libelle: "Sondes",
        lien: "/admin/dash/sonde",
        tague: "emballages-sonde",
      },
      {
        libelle: "DiagnoBag",
        lien: "/admin/dash/daignobag",
        tague: "emballages-daignobag",
      }
    ]
  },
];
