import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import centreFacturationService from "../../../services/centreFacturation/centreFacturationService";
import FacturationCode from "../../Utils/Selectors/FacturationLineSelector";
import Select from "react-select";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { decodePHPArray } from "../../../utils/Functions/otherFunctions";
import {
  addFacturation,
  removeFacturation,
} from "../../../features/slices/expeditionSlice";
import { validateFactureLineData } from "../../../utils/validators";
import { getWeightVolume, roundToTenth, toFixed2 } from "../../../utils";
import moment from "moment";
import { CommentaireList } from "./CommentaireTabsComponent";
import { useSearchParams } from "react-router-dom";
import { TableComponent } from "../../TableauComponent";
import { useAppContext } from "../../../context";
import { ShippingDetails } from "../../../store/shippingDetails";
import { EmballageDetails } from "../emballageDetails";

export const FactureTabsComponent = (props) => {

  const expeditionDetails = ShippingDetails((state) => state.details);
  const updateFactureLine = ShippingDetails((state) => state.updateFactureLine);
  const addFactureLine = ShippingDetails((state) => state.addFactureLine);
  const removeFactureLine = ShippingDetails((state) => state.removeFactureLine);
  const resetFactureLine = ShippingDetails((state) => state.resetFactureLine);
  const factureLine = ShippingDetails((state) => state.factureLine);


  const { isAuth, setIsAuth } = useAppContext();

  const dispatch = useDispatch();

  const [facturationCode, setFacturationCode] = useState("");

  const [search] = useSearchParams();
  const factureAvoir = search.get("factureAvoir");
  const formattedRef = search.get("formattedRef");



  useEffect(() => {

    if (formattedRef) {
      updateFactureLine("comment", `Avoir de la facture ${formattedRef}`)
      updateFactureLine("line_type", { label: "Avoir", value: "Avoir" })
    }
  }, [formattedRef])


  const handleDelete = (identifiant, classification) => {
    if (classification === "archived") {
      toast.error("Impossible de supprimer cette ligne de facturation", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      Swal.fire({
        title: "Êtes-vous sûr de vouloir supprimer ?",
        text: "Cette action est irréversible !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: isAuth?.spaceName === 'paris' ? "#ba9964" : (isAuth?.spaceName === 'service' ? "#098721" : (isAuth?.spaceName === 'medical' ? "#3A8EBA" : "#222")),
        confirmButtonText: "Oui, supprimer",
        cancelButtonText: "Annuler",
      }).then((result) => {
        if (result.isConfirmed) {
          handleDeleteAction(identifiant);
        } else {
          Swal.fire("Annuler", "Suppression annulée avec succès", "error", 500);
        }
      });
    }
  };

  const handleDeleteAction = (id) => {
    if (id) {
      let option = {
        facturation_id: id,
      };
      try {
        const toastsId = toast.loading("Suppression en cours ...", {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        centreFacturationService.deleteFacturationLine(option).then(
          (response) => {
            //console.log(response);
            if (response.data.success) {
              removeFactureLine(id);
              toast.update(toastsId, {
                render: "Ligne de facturation supprimer",
                type: "success",
                isLoading: false,
                autoClose: 500,
                position: "top-center",
              });
            } else {
              toast.update(toastsId, {
                render: "Impossible de supprimer cette ligne de facturation",
                type: "error",
                isLoading: false,
                autoClose: 500,
                position: "top-center",
              });
            }
          },
          (error) => {
            toast.update(toastsId, {
              render:
                "Erreur lors de la  supprission de la ligne de facturation",
              type: "error",
              isLoading: false,
              autoClose: 500,
              position: "top-center",
            });
          }
        );
      } catch (error) {
        toast.error(
          "Erreur lors de la  supprission de la ligne de facturation",
          {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    }
  };

  const handleSubmit = () => {
    let { line_type, ...otherValue } = factureLine;
    let valeur = {
      ...otherValue,
      id: parseInt(facturationCode.split("|||")[0]),
      line_type: line_type?.value,
    };
    const { error } = validateFactureLineData(valeur);
    if (error) {
      toast.error(error, {
        autoClose: 500,
        position: "top-center",
      });
    } else {
      document.getElementById("submitting").setAttribute("disabled", true);
      try {
        const toastsId = toast.loading("Enregistrement en cours ...", {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        let option = {
          shipping_id: expeditionDetails.id,
          facturation_lines: {
            ...valeur,
          },
        };

        centreFacturationService.AddFacturationLine(option).then(
          (response) => {
            let { data } = response;

            if (data.success) {
              addFactureLine(data.data);
              toast.update(toastsId, {
                render: "Ligne de facturation enregistrée",
                type: "success",
                isLoading: false,
                autoClose: 500,
                position: "top-center",
              });
              resetFactureLine();
              setFacturationCode("")
              document.getElementById("submitting")?.removeAttribute("disabled");
            } else {
              document.getElementById("submitting")?.removeAttribute("disabled");
              toast.update(toastsId, {
                render: "Ligne de facturation non enregistrée",
                type: "error",
                isLoading: false,
                autoClose: 500,
                position: "top-center",
              });
            }
          },
          (error) => {
            document.getElementById("submitting")?.removeAttribute("disabled");
            toast.update(toastsId, {
              render: "Ligne de facturation non enregistrée",
              type: "error",
              isLoading: false,
              autoClose: 500,
              position: "top-center",
            });
          }
        );
      } catch (error) {
        document.getElementById("submitting")?.removeAttribute("disabled");
        toast.dismiss();
        toast.error("Erreur lors de l'enregistrement", {
          autoClose: 500,
          position: "top-center",
        });
      }
    }
  };


  const calculPrix = () => {
    const montantPq = Number(factureLine.price) * Number(factureLine.quantity);
    const fuelPercentage = facturationCode?.split("|||")[1];
    const montantFuel =
      (montantPq *
        Number(fuelPercentage)) /
      100;

    const totalHT = montantPq + montantFuel;


    const tvaPercentage = facturationCode ? (parseInt(facturationCode?.split("|||")[2]) === 1 ? 0.2 : 0) : 0;
    const tva = totalHT * tvaPercentage;
    const total_final = totalHT + tva;

    console.log({ totalHT, tva, montantFuel, montantPq, total_final, p: facturationCode?.split("|||")[1] })

    return toFixed2(total_final);
  };

  const isCourse = expeditionDetails.courses?.length > 0;
  const item = expeditionDetails ? expeditionDetails[isCourse ? "courses" : "packages"]?.[0] : null


  return (
    <div className={""}>
      {isAuth?.spaceName === "medical" &&

        <div className="h-full my-3 px-2 uppercase bg-white rounded-md">
          <div className="text-center  flex items-center justify-center text-base font-semibold h-[40px] py-6">
            Fourniture d’emballage
          </div>
          <hr />

          <EmballageDetails data={expeditionDetails["shipping_emballage"]?.[0]} />
        </div>
      }


      <div className="grid-cols-12 gap-1 space-y-2 md:grid md:gap-4 md:space-y-0">
        <div className="h-full col-span-4 bg-white rounded-md">
          <div className="row">
            <div className="p-2">
              <div className="form">
                <div className="my-1">
                  <label htmlFor="name" className="my-1 text-base ">
                    Code de facturation
                  </label>

                  <FacturationCode
                    selectedValue={facturationCode}
                    onSelect={(v) => {
                      //console.log({ v })
                      setFacturationCode(v?.value);
                      updateFactureLine("price", v.montant)
                    }}
                    showLabel={false}
                  />
                </div>
                <div className="my-1">
                  <label htmlFor="name" className="my-1 text-base ">
                    Type de ligne
                  </label>
                  <Select
                    className={"text-sm "}
                    options={[
                      { value: "Facture", label: "Facture" },
                      { value: "Avoir", label: "Avoir" },
                    ]}
                    value={factureLine?.line_type}
                    placeholder={"Choisir un type"}
                    onChange={(v) => {
                      updateFactureLine("line_type", v);

                    }}
                  />
                </div>

                <div className="row">
                  <div className="col-md-8">
                    <div className="my-1">
                      <label htmlFor="name" className="my-1 text-base ">
                        Prix
                      </label>
                      <input
                        type="text"
                        className="px-2 block w-full border-0 py-1.5 pr-10  ring-1 ring-inset ring-gray-300  focus:ring-2 focus:ring-inset text-base sm:leading-6 focus:outline-none"
                        placeholder="Prix"
                        // min={0}
                        value={factureLine.price}
                        onChange={(e) => {
                          updateFactureLine("price", e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="my-1">
                      <label htmlFor="name" className="my-1 text-base ">
                        Quantité
                      </label>
                      <input
                        type="text"
                        className="px-2 block w-full border-0 py-1.5 pr-10  ring-1 ring-inset ring-gray-300  focus:ring-2 focus:ring-inset text-base sm:leading-6 focus:outline-none"
                        placeholder="Quantité"
                        //min={1}
                        value={factureLine.quantity}
                        onChange={(e) => { updateFactureLine("quantity", e.target.value) }}
                      />
                    </div>
                  </div>
                </div>

                <div className="my-1">
                  <label htmlFor="name" className="my-1 text-base ">
                    Prix total
                  </label>
                  <input
                    type="text"
                    className="px-2 block w-full border-0 py-1.5 pr-10  ring-1 ring-inset ring-gray-300  focus:ring-2 focus:ring-inset text-base sm:leading-6 focus:outline-none"
                    placeholder="Prix total"
                    //min={0}
                    value={calculPrix()}
                    disabled
                  />
                </div>

                <div className="my-1">
                  <label htmlFor="name" className="my-1 text-base ">
                    Commentaire
                  </label>
                  <textarea
                    className={"px-2 focus:border-gray-300 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-gray-300  focus:ring-2 text-base sm:leading-6 h-auto border-gray-300 focus:ring-gray-300 "}
                    placeholder='Votre commentaire'
                    name="content"
                    id="content"
                    rows={5}
                    resize={"none"}
                    value={factureLine?.comment}
                    onChange={(e) => updateFactureLine("comment", e.target.value)}
                  ></textarea>


                </div>


                <div className={"d-flex justify-end "}>
                  <button
                    id="submitting"
                    onClick={handleSubmit}
                    type={"button"}
                    className={"p-2 rounded-md bg-black text-white"}
                    disabled={!facturationCode}
                  >
                    Enregistrer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="h-full col-span-8 bg-white rounded-md ">
          <div className="">
            <div className="p-2">


              {expeditionDetails?.packages && expeditionDetails?.packages?.length > 0 && (
                <div className="grid-cols-5 gap-4 md:grid">
                  <div className="col ">
                    <h4 className="text-lg">Poids</h4>
                    <span className="text-sm">
                      {expeditionDetails?.packages[0]?.weight}
                    </span>
                  </div>

                  <div className="col ">
                    <h4 className="text-lg">Poids volumétrique</h4>
                    <span className="text-sm">
                      {expeditionDetails?.packages[0] ? toFixed2(roundToTenth(getWeightVolume(expeditionDetails?.packages[0]))) : "-"}
                      {" "}
                    </span>
                  </div>
                  <div className="col ">
                    <h4 className="text-lg">Longeur</h4>
                    <span className="text-sm">
                      {" "}
                      {expeditionDetails?.packages[0]?.length}
                    </span>
                  </div>

                  <div className="col ">
                    <h4 className="text-lg">Largeur</h4>
                    <span className="text-sm">
                      {" "}
                      {expeditionDetails?.packages[0]?.width}
                    </span>
                  </div>

                  <div className="col ">
                    <h4 className="text-lg">Hauteur</h4>
                    <span className="text-sm">
                      {expeditionDetails?.packages[0]?.height}
                    </span>
                  </div>
                </div>
              )}

              {expeditionDetails?.courses && expeditionDetails?.courses?.length > 0 && (
                <div className="grid-cols-3 gap-2 space-y-2 md:grid md:space-y-0 ">
                  <div className="col ">
                    <h4 className="text-lg">Valeur</h4>
                    <span className="text-sm">
                      {expeditionDetails?.courses[0]?.valeur_douane
                        ? expeditionDetails?.courses[0]?.valeur_douane
                        : "Non renseigné"}
                    </span>
                  </div>

                  <div className="col ">
                    <h4 className="text-lg">Porteur</h4>
                    <span className="text-sm">
                      {expeditionDetails?.courses[0]?.porteur
                        ? expeditionDetails?.courses[0]?.porteur
                        : "Non renseigné"}
                    </span>
                  </div>
                  <div className="col ">
                    <h4 className="text-lg">Description</h4>
                    <span className="text-sm">
                      {expeditionDetails?.courses[0]?.package_description
                        ? expeditionDetails?.courses[0]?.package_description
                        : "Non renseigné"}
                    </span>
                  </div>
                </div>
              )}

              <div className="grid gap-8 my-5 md:grid-cols-5">
                <div className="">
                  <h4 className="text-lg">Numéro de devis</h4>
                  {item && <span className="text-sm">{item.nombre_devi}</span>}
                </div>
                <div className="">
                  <h4 className="text-lg">Montant de devis</h4>
                  {item && <span className="text-sm">{item.montant_devi}€</span>}
                </div>

                <div className="">
                  <h4 className="text-lg">Numéro de compte</h4>
                  <span className="text-sm">{expeditionDetails?.detail_info_supplementaire?.numero_compte}</span>
                </div>
                <div className="">
                  <h4 className="text-lg">Numéro MAWB</h4>
                  <span className="text-sm">{expeditionDetails?.detail_info_supplementaire?.mawb}</span>
                </div>

                <div className="">
                  <h4 className="text-lg">Type de véhicule</h4>
                  <span className="text-sm">
                    {expeditionDetails?.type_de_roue == 2
                      ? "Deux roues"
                      : expeditionDetails?.type_de_roue == 4 ? "Quatre roues" : ''}
                  </span>
                </div>
                <div className="">
                  <h4 className="text-lg">Type d'opération</h4>
                  <span className="text-sm">
                    {expeditionDetails?.type_expedition?.toUpperCase()}
                  </span>
                </div>

                <div className="">
                  <h4 className="text-lg">Date d'enlèvement</h4>
                  <span className="text-sm">
                    {moment(expeditionDetails.pickup_date).format("DD-MM-YYYY")}
                  </span>
                </div>
                <div className="">
                  <h4 className="text-lg">Expéditeur</h4>
                  <span className="text-sm">
                    {expeditionDetails?.expeditor?.city} /{" "}
                    {expeditionDetails?.expeditor?.country}
                  </span>
                </div>
                <div className="">
                  <h4 className="text-lg">Destinaire</h4>
                  <span className="text-sm">
                    {" "}
                    {expeditionDetails?.receiver?.city} /{" "}
                    {expeditionDetails?.receiver?.country}
                  </span>
                </div>
              </div>
              <hr className="w-full my-1 divide-x-8" />
              <div>

                <CommentaireList comments={expeditionDetails?.comments} />
              </div>
            </div>
          </div>
        </div>
      </div>


      <TableComponent withAction={true} headerList={tableHead} dataLength={expeditionDetails?.facturations?.length} isLoading={false}>
        {expeditionDetails?.facturations?.map((item, index) => {
          const isAvoir = item?.line_type?.toLowerCase() === "avoir"
          return (
            <tr className="">
              <td>{item?.line_type}</td>
              <td>{item?.facturation_line?.label}</td>
              <td>{item?.facturation_line?.description}</td>
              <td>{item ? `${isAvoir ? "-" : ""} ${toFixed2(item.prix * item.qte)}` : null}</td>
              <td>{item?.qte}</td>
              <td>{isAvoir ? "-" : ""}{toFixed2(item?.fuel)}</td>
              <td>{isAvoir ? "-" : ""}{toFixed2(item?.total)}</td>
              <td>{isAvoir ? "-" : ""}{toFixed2(item?.tva)}</td>
              <td>{item?.comment}</td>
              <td>{isAvoir ? "-" : ""}{item?.total_final}</td>
              <td className={" text-center"}>
                <span
                  className={`fa fa-trash text-${decodePHPArray(item?.classifications) === "archived"
                    ? "dark"
                    : "danger"
                    } text-center pointer`}
                  onClick={() =>
                    handleDelete(
                      item?.id,
                      decodePHPArray(item?.classifications)
                    )
                  }
                ></span>
              </td>
            </tr>
          )
        })}
      </TableComponent>


    </div>
  );
}


const tableHead = [
  { "label": "Type", "filterKey": "type" },
  { "label": "Libelle", "filterKey": "libelle" },
  { "label": "Desc", "filterKey": "desc" },
  { "label": "Prix HT", "filterKey": "prix_ht" },
  { "label": "QUANTITÉ", "filterKey": "quantite" },
  { "label": "FUEL", "filterKey": "fuel" },
  { "label": "TOTAL HT", "filterKey": "total_ht" },
  { "label": "TVA", "filterKey": "tva" },
  { "label": "COMMENTAIRE", "filterKey": "commentaire" },
  { "label": "PRIX FINAL", "filterKey": "prix_final" }
]

