import axios from "axios";
import TokenService from "../config/tokenService.js";

class Auth {

    constructor() {
        TokenService.setHeader();
    }
    Login(email, password, interfac, type) {

        return axios.post(`${process.env.REACT_APP_API_URL}/login`, { email, password, interface: interfac, type })
    }

    Logout() {
        localStorage.removeItem("client");
        return axios.get(`${process.env.REACT_APP_API_URL}/reste_du_chemin`)
    }


    // Il faut appelé cette méthode lorsque l'utilisteur est connecté
    // Elle permet de stocké le token dans le header des requetes axios

    setHeader(token) {
       
        let tokenValue = token ?? localStorage.getItem("client");
        axios.defaults.headers.common['Authorization'] = `Bearer ${tokenValue}`;
    }
}

export default new Auth();
