
import medicalImport from "../../../assets/img/svg/Medicals-import.svg";
import medicalExport from "../../../assets/img/svg/Medicals-export.svg";
import medicalcourse from "../../../assets/img/svg/Medicals.svg";
import expeditionArchiveLogo from "../../../assets/img/Icones/expedition/medicals.svg";
import factureLogo from "../../../assets/img/Icones/Facture/medicals.svg";
import historiqueLogo from "../../../assets/img/Icones/historique/medicals.svg";
import plusLogo from "../../../assets/img/Icones/plus/medicals.svg";
import explotationLogo from "../../../assets/img/Icones/exploitation/medicals.svg";
import dashLogo from "../../../assets/img/Icones/Dashboard/medicals.svg";

export const opsMedicalMenu = [
  {
    isMultiple: true,
    tague: "dashboard",
    isTitle: false,
    libelle: "Dashboard",
    icon: <img alt={"fd"} src={dashLogo} className={` w-8 hover:text-black`} />,
    liens: [
      {
        libelle: "Expéditions en attente",
        lien: "/ops/medical/dash/en-attente",
        key: "shippingPending",
        tague: "dashboard-pending",
      },
      {
        libelle: "Expéditions en cours",
        lien: "/ops/medical/dash/en-cours",
        key: "shippingActive",
        tague: "dashboard-progress",
      },
      {
        libelle: "Expéditions livrées",
        lien: "/ops/medical/dash/livre",
        key: "shippingDelivered",
        tague: "dashboard-delivered",
      },
      {
        libelle: "Expéditions à archiver",
        lien: "/ops/medical/dash/a-archivee",
        key: "shippingTobeArchived",
        tague: "dashboard-for-achived",
      },
      {
        libelle: "Expéditions facturées",
        lien: "/ops/medical/dash/facturee",
        key: "shippingFactured",
        tague: "dashboard-factured",
      },
      {
        libelle: "Expéditions annulées",
        lien: "/ops/medical/dash/annulee",
        key: "shippingCancelled",
        tague: "dashboard-cancelled",
      },
      {
        libelle: "Manifest",
        lien: "/ops/medical/dash/manifest",
        key: "shippingManifest",
        tague: "dashboard-manifest",
      },
    ],
  },
  // {
  //   libelle: "Les courses",
  //   isTitle:true,
  // },
  // {
  //   isMultiple: true,
  //   tague: "courses",
  //   isTitle: false,
  //   libelle: "Liste des courses",
  //   icon: (
  //     <img alt={"fd"} src={medicalcourse} className={` w-8 hover:text-black`} />
  //   ),
  //   liens: [
  //     {
  //       libelle: "Courses en attente",
  //       lien: "/ops/medical/dash/courses-a-courses/en-attente",
  //       tague: "courses-pending",
  //     },
  //     {
  //       libelle: "Courses en cours",
  //       lien: "/ops/medical/dash/courses-a-courses/en-cours",
  //       tague: "courses-progress",
  //     },
  //     {
  //       libelle: "Courses livrées",
  //       lien: "/ops/medical/dash/courses-a-courses/livre",
  //       tague: "courses-delivered",
  //     },
  //     {
  //       libelle: "Courses à archiver",
  //       lien: "/ops/medical/dash/courses-a-courses/a-archivee",
  //       tague: "courses-for-archived",
  //     },
  //     {
  //       libelle: "Courses facturées",
  //       lien: "/ops/medical/dash/courses-a-courses/facturee",
  //       tague: "courses-factured",
  //     },
  //     {
  //       libelle: "Courses annulées",
  //       lien: "/ops/medical/dash/courses-a-courses/annulee",
  //       tague: "courses-cancelled",
  //     },
  //     {
  //       libelle: "Manifest",
  //       lien: "/ops/medical/dash/manifest",
  //       tague: "courses-manifest",
  //     },
  //   ],
  // },
  // {
  //   isMultiple: false,
  //   tague: "export",
  //   isTitle: false,
  //   libelle: "Nouvelle course",
  //   lien: "/ops/medical/dash/courses-a-courses/new",
  //   icon: <img alt={"fd"} src={plusLogo} className={` w-8 hover:text-black`} />,
  // },
  {
    libelle: "Les exports",
    isTitle: true,
  },
  {
    isMultiple: true,
    tague: "exports",
    isTitle: false,
    libelle: "Liste des exports",
    icon: (
      <img alt={"fd"} src={medicalExport} className={` w-8 hover:text-black`} />
    ),
    liens: [
      {
        libelle: "Exports en attente",
        lien: "/ops/medical/dash/export/en-attente",
         tague: "exports-pending",
      },
      {
        libelle: "Exports en cours",
        lien: "/ops/medical/dash/export/en-cours",
         tague: "exports-progresse",
      },
      {
        libelle: "Exports livrées",
        lien: "/ops/medical/dash/export/livre",
         tague: "exports-delivered",
      },
      {
        libelle: "Exports à archiver",
        lien: "/ops/medical/dash/export/a-archivee",
         tague: "exports-for-archived",
      },
      {
        libelle: "Exports facturées",
        lien: "/ops/medical/dash/export/facturee",
         tague: "exports-factured",
      },
      {
        libelle: "Exports annulées",
        lien: "/ops/medical/dash/export/annulee",
         tague: "exports-cancelled",
      },
      {
        libelle: "Manifest",
        lien: "/ops/medical/dash/manifest",
         tague: "exports-manifest",
      },
    ],
  },
  {
    isMultiple: false,
    tague: "exports-new",
    isTitle: false,
    libelle: "Nouvel export",
    lien: "/ops/medical/dash/export/new",
    icon: <img alt={"fd"} src={plusLogo} className={` w-8 hover:text-black`} />,
  },
  {
    libelle: "Les imports",
    isTitle:true,
  },
  {
    isMultiple: true,
    tague: "import",
    isTitle: false,
    libelle: "Liste des imports",
    icon: (
      <img alt={"fd"} src={medicalImport} className={` w-8 hover:text-black`} />
    ),
    liens: [
      {
        libelle: "Imports en attente",
        lien: "/ops/medical/dash/import/en-attente",
         tague: "import-en-attente",
      },
      {
        libelle: "Imports en cours",
        lien: "/ops/medical/dash/import/en-cours",
         tague: "import-en-cours",
      },
      {
        libelle: "Imports livrées",
        lien: "/ops/medical/dash/import/livre",
         tague: "import-livre",
      },
      {
        libelle: "Imports à archiver",
        lien: "/ops/medical/dash/import/a-archivee",
         tague: "import-a-archivee",
      },
      {
        libelle: "Imports facturées",
        lien: "/ops/medical/dash/import/facturee",
         tague: "import-facturee",
      },
      {
        libelle: "Imports annulées",
        lien: "/ops/medical/dash/import/annulee",
         tague: "import-annulee",
      },
      {
        libelle: "Manifest",
        lien: "/ops/medical/dash/manifest",
         tague: "import-manifest",
      },
    ],
  },
  {
    isMultiple: false,
    tague: "import-new",
    isTitle: false,
    libelle: "Nouvel import",
    lien: "/ops/medical/dash/import/new",
    icon: <img alt={"fd"} src={plusLogo} className={` w-8 hover:text-black`} />,
  },
  {
    isMultiple: false,
    tague: "emballages",
    isTitle: false,
    libelle: "Les emballages",
    lien: "/ops/paris/dash/emballages",
    icon: <img alt={'fd'} src={dashLogo} className={` w-8 hover:text-black`} />,
    key: "emballages"
},
  {
    libelle: "Gestion",
    isTitle:true
  },
  {
    isMultiple: true,
    tague: "factures",
    isTitle: false,
    libelle: "Factures",
    icon: (
      <img alt={"fd"} src={factureLogo} className={` w-8 hover:text-black`} />
    ),
    key: "facture",
    liens: [
      {
        libelle: "Liste des factures",
        lien: "/ops/medical/dash/factures/listes",
        tague: "factures-listes",
      },
      {
        libelle: "Bordereaux archivés",
        lien: "/ops/medical/dash/factures/bordereaux",
        tague: "factures-bordereaux",
      },
      {
        libelle: "Extractions",
        lien: "/ops/medical/dash/factures/extractions",
        tague: "factures-extractions",
      },
      {
        libelle: "Liste des factures globales",
        lien: "/ops/medical/dash/factures/liste-globale",
        tague: "factures-liste-globale",
      },
    ],
  },
  {
    isMultiple: false,
    tague: "archived",
    isTitle: false,
    libelle: "Expéditions archivées",
    lien: "/ops/medical/dash/archivee",
    icon: (
      <img
        alt={"fd"}
        src={expeditionArchiveLogo}
        className={` w-8 hover:text-black`}
      />
    ),
    key: "archived",
  },
  // {
   
  //   isMultiple: false,
  //   tague: "dispatch",
  //   isTitle: false,
  //   libelle: "Exploitation / dispatch",
  //   lien: "/exploitation/medical/auth",
  //   icon: (
  //     <img
  //       alt={"fd"}
  //       src={explotationLogo}
  //       className={` w-8 hover:text-black`}
  //     />
  //   ),
  // },
  {
    isMultiple: false,
    tague: "history",
    isTitle: false,
    libelle: "Le 24 Heures",
    lien: "/ops/medical/dash/history",
    icon: (
      <img
        alt={"fd"}
        src={historiqueLogo}
        className={` w-8 hover:text-black`}
      />
    ),
  },
];
