// create Fuel react service

import axios from "axios";
import TokenService from "../config/tokenService.js";

class FacturationCodeService {

    constructor() {
        TokenService.setHeader();
    }
    lists(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/facturation-codes`, data);
    }

    add(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/facturation-code/create`, data);
    }

    edit(fuel) {
        return axios.put(`${process.env.REACT_APP_API_URL}/admin/facturation-code`, fuel);
    }

    delete(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/facturation-code/desactiver`, data);
    }



}

export default new FacturationCodeService();
