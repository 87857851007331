import servicesImport from "../../../assets/img/svg/Services-import.svg";
import servicesExport from "../../../assets/img/svg/Services-export.svg";
import servicescourse from "../../../assets/img/svg/Services.svg";
import archiveLogo from "../../../assets/img/Icones/archive/services.svg";
import factureLogo from "../../../assets/img/Icones/Facture/services.svg";
import historiqueLogo from "../../../assets/img/Icones/historique/services.svg";
import plusLogo from "../../../assets/img/Icones/plus/services.svg";
import explotationLogo from "../../../assets/img/Icones/exploitation/services.svg";
import dashLogo from "../../../assets/img/Icones/Dashboard/services.svg";

export const opsServiceMenu = [
  {
    isMultiple: true,
    tague: "dashboard",
    isTitle: false,
    libelle: "Dashboard",
    icon: <img alt={"fd"} src={dashLogo} className={` w-8 hover:text-black`} />,
    liens: [
      {
        libelle: "Expéditions en attente",
        lien: "/ops/service/dash/en-attente",
        key: "shippingPending",
        tague: "dashboard-en-attente",
      },
      {
        libelle: "Expéditions en cours",
        lien: "/ops/service/dash/en-cours",
        key: "shippingActive",
        tague: "dashboard-en-cours",
      },
      {
        libelle: "Expéditions livrées",
        lien: "/ops/service/dash/livre",
        key: "shippingDelivered",
        tague: "dashboard-livre",
      },
      {
        libelle: "Expéditions à archiver",
        lien: "/ops/service/dash/a-archivee",
        key: "shippingArchived",
        tague: "dashboard-/a-archivee",
      },
      {
        libelle: "Expéditions facturées",
        lien: "/ops/service/dash/facturee",
        key: "shippingFactured",
        tague: "dashboard-facturee",
      },
      {
        libelle: "Expéditions annulées",
        lien: "/ops/service/dash/annulee",
        key: "shippingCancelled",
        tague: "dashboard-annulee",
      },
      {
        libelle: "Manifest",
        lien: "/ops/service/dash/manifest",
        key: "shippingManifest",
        tague: "dashboard-manifest",
      },
    ],
  },
  {
    libelle: "Les courses",
    isTitle: true,
  },
  {
    isMultiple: true,
    tague: "courses",
    isTitle: false,
    libelle: "Liste des courses",
    icon: (
      <img
        alt={"fd"}
        src={servicescourse}
        className={` w-8 hover:text-black`}
      />
    ),
    liens: [
      {
        libelle: "Courses en attente",
        lien: "/ops/service/dash/courses-a-courses/en-attente",
        tague: "courses-en-attente",
      },
      {
        libelle: "Courses en cours",
        lien: "/ops/service/dash/courses-a-courses/en-cours",
        tague: "courses-en-cours",
      },
      {
        libelle: "Courses livrées",
        lien: "/ops/service/dash/courses-a-courses/livre",
        tague: "courses-livre",
      },
      {
        libelle: "Courses à archiver",
        lien: "/ops/service/dash/courses-a-courses/a-archivee",
        tague: "courses-a-archivee",
      },
      {
        libelle: "Courses facturées",
        lien: "/ops/service/dash/courses-a-courses/facturee",
        tague: "courses-facturee",
      },
      {
        libelle: "Courses annulées",
        lien: "/ops/service/dash/courses-a-courses/annulee",
        tague: "courses-annulee",
      },
      {
        libelle: "Manifest",
        lien: "/ops/service/dash/manifest",
        tague: "courses-manifest",
      },
    ],
  },
  {
    isMultiple: false,
    tague: "course-new",
    isTitle: false,
    libelle: "Nouvelle course",
    lien: "/ops/service/dash/courses-a-courses/new",
    icon: <img alt={"fd"} src={plusLogo} className={` w-8 hover:text-black`} />,
  },
  {
    libelle: "Les exports",
    isTitle: true,
  },
  {
    isMultiple: true,
    tague: "exports",
    isTitle: false,
    libelle: "Liste des exports",
    icon: (
      <img
        alt={"fd"}
        src={servicesExport}
        className={` w-8 hover:text-black`}
      />
    ),
    liens: [
      {
        libelle: "Exports en attente",
        lien: "/ops/service/dash/export/en-attente",
        tague: "exports-en-attente",
      },
      {
        libelle: "Exports en cours",
        lien: "/ops/service/dash/export/en-cours",
        tague: "exports-en-cours",
      },
      {
        libelle: "Exports livrées",
        lien: "/ops/service/dash/export/livre",
        tague: "exports-livre",
      },
      {
        libelle: "Exports à archiver",
        lien: "/ops/service/dash/export/a-archivee",
        tague: "exports-a-archivee",
      },
      {
        libelle: "Exports facturées",
        lien: "/ops/service/dash/export/facturee",
        tague: "exports-facturee",
      },
      {
        libelle: "Exports annulées",
        lien: "/ops/service/dash/export/annulee",
        tague: "exports-annulee",
      },
      {
        libelle: "Manifest",
        lien: "/ops/service/dash/manifest",
        tague: "exports-manifest",
      },
    ],
  },
  {
    isMultiple: false,
    tague: "export-new",
    isTitle: false,
    libelle: "Nouvel export",
    lien: "/ops/service/dash/export/new",
    icon: <img alt={"fd"} src={plusLogo} className={` w-8 hover:text-black`} />,
  },
  {
    libelle: "Les imports",
    isTitle: true,
  },
  {
    isMultiple: true,
    tague: "imports",
    isTitle: false,
    libelle: "Liste des imports",
    icon: (
      <img
        alt={"fd"}
        src={servicesImport}
        className={` w-8 hover:text-black`}
      />
    ),
    liens: [
      {
        libelle: "Imports en attente",
        lien: "/ops/service/dash/import/en-attente",
        tague: "imports-en-attente",
      },
      {
        libelle: "Imports en cours",
        lien: "/ops/service/dash/import/en-cours",
        tague: "imports-en-cours",
      },
      {
        libelle: "Imports livrées",
        lien: "/ops/service/dash/import/livre",
        tague: "imports-livre",
      },
      {
        libelle: "Imports à archiver",
        lien: "/ops/service/dash/import/a-archivee",
        tague: "imports-a-archivee",
      },
      {
        libelle: "Imports facturées",
        lien: "/ops/service/dash/import/facturee",
        tague: "imports-facturee",
      },
      {
        libelle: "Imports annulées",
        lien: "/ops/service/dash/import/annulee",
        tague: "imports-annulee",
      },
      {
        libelle: "Manifest",
        lien: "/ops/service/dash/manifest",
        tague: "imports-manifest",
      },
    ],
  },
  {
    isMultiple: false,
    tague: "import-new",
    isTitle: false,
    libelle: "Nouvel import",
    lien: "/ops/service/dash/import/new",
    icon: <img alt={"fd"} src={plusLogo} className={` w-8 hover:text-black`} />,
  },
  {
    isMultiple: false,
    tague: "emballages",
    isTitle: false,
    libelle: "Les emballages",
    lien: "/ops/paris/dash/emballages",
    icon: <img alt={"fd"} src={dashLogo} className={` w-8 hover:text-black`} />,
    key: "emballages",
  },
  {
    libelle: "Gestion",
    isTitle: true,
  },
  {
    isMultiple: true,
    tague: "factures",
    isTitle: false,
    libelle: "Factures",
    icon: (
      <img alt={"fd"} src={factureLogo} className={` w-8 hover:text-black`} />
    ),
    key: "facture",
    liens: [
      {
        libelle: "Liste des factures",
        lien: "/ops/service/dash/factures/listes",
        tague: "factures-listes",
      },
      {
        libelle: "Bordereaux archivés",
        lien: "/ops/service/dash/factures/bordereaux",
        tague: "factures-bordereaux",
      },
      {
        libelle: "Extractions",
        lien: "/ops/service/dash/factures/extractions",
        tague: "factures-extractions",
      },
      {
        libelle: "Liste des factures globales",
        lien: "/ops/service/dash/factures/liste-globale",
        tague: "factures-liste-globale",
      },
    ],
  },
  {
    isMultiple: false,
    tague: "archivees",
    isTitle: false,
    libelle: "Expéditions archivées",
    lien: "/ops/service/dash/archivee",
    icon: (
      <img alt={"fd"} src={archiveLogo} className={` w-8 hover:text-black`} />
    ),
    key: "archived",
  },
  // {
  //     isMultiple: false,
  //     tague: "exploitation",
  //     isTitle: false,
  //     libelle: "Exploitation / dispatch",
  //     lien: "/exploitation/service/auth",
  //     icon: <img alt={'fd'} src={explotationLogo} className={` w-8 hover:text-black`} />,
  // },
  {
    isMultiple: false,
    tague: "historique",
    isTitle: false,
    libelle: "Le 24 Heures",
    lien: "/ops/service/dash/history",
    icon: (
      <img
        alt={"fd"}
        src={historiqueLogo}
        className={` w-8 hover:text-black`}
      />
    ),
  },
];
