import vehiculeService from "../../../services/vehicule/vehiculeService.js";

export const AdminVehiculesTableDataController = async ( nbrPage,process) => {

    switch (process){
        case "list":
            try {
                const data = await vehiculeService.fetchListVehicules({
                    //type: (filter ? (filter.type ?? "all") : "all"),
                    per_page: import.meta.env.VITE_REACT_DATA_PER_PAGE,
                    page: nbrPage,
                });

                let response = data.data;

                return {
                    success: true,
                    data: response,

                };


            } catch (error) {
                return {
                    success: false,
                    data: error,
                };
            }
        case "update":

            break;
    }
};
