// create contact react service

import axios from "axios";
import TokenService from "../config/tokenService.js";

class ContactService {
    constructor() {
        TokenService.setHeader();
    }
    fetchContacts() {
        return axios.get(`${process.env.REACT_APP_API_URL}/contact`);
    }

    fetchFilteredContacts(filterQuery) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/contact`,
            filterQuery
        );
    }

    fetchAdminFilteredContacts(filterQuery) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/admin/contacts`,
            filterQuery
        );
    }

    fetchContactById(contactId) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/contact/${contactId}`
        );
    }

    deleteContact(contactId) {
        return axios.delete(
            `${process.env.REACT_APP_API_URL}/contact/${contactId}`
        );
    }

    addContact(contact) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/contact`,
            contact
        );
    }

    editContact(contact) {
        return axios.put(
            `${process.env.REACT_APP_API_URL}/contact/${contact.id}`,
            contact
        );
    }

    fechClientConatctList(option) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/contact`,
            option
        );
    }

    fechClientFilteredConatctList(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/contact`,
            data
        );
    }

    clientCreateContact(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/contact/create`,
            data
        );
    }

    clientCreateContactUpload(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/contact/create/import`,
            data
        );
    }



    clientUpdateContact(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/contact/update`,
            data
        );
    }


    clientDeleteContact(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/contact/delete`,
            data
        );
    }
}

export default new ContactService();
