export const DernierExpedition = ['REF', 'ENLEV', 'EXP', 'Adresse', 'DEST', 'Adresse', 'LIVR', 'TRACK', 'DISPATCH', 'ACTION'];

export const ClientList = ['ID', 'NOM DU CLIENT', 'TYPE', 'PAYS', 'ADRESSE', 'VILLE', 'Téléphone', 'EMAIL DE FACTURATION', 'TVA', "DATE AJOUT ", 'ACTIF', 'ACTION'];

export const CentreFacturation = ['ID', 'NOM DU CENTRE', 'TYPE', 'CLIENT', 'PAYS', 'ADRESSE', 'VILLE', 'EMAIL', 'Téléphone', 'UTILISATEURS LIéS', "DATE DE CRÉATION", 'ACTIF', 'ACTION'];

export const UtilisateurList = ['ID', "NOM D'UTILISATEUR", 'TYPE', 'PAYS', 'ADRESSE', 'Téléphone', 'ADRESSE EMAIL', 'ENTREPRISE', 'ENTREPRISE PHYSIQUE', "DATE DE CREATION", 'ACTIF', 'ACTION'];

export const ContactList = ['ID', 'CIVILITE', 'NOM ET PRENOM', 'ENTREPRISE/SOCIETE', 'PAYS', 'Adresse', 'CODE POSTAL', 'VILLE', 'Téléphone 1', 'TYPE', 'ACTION'];

export const AdminList = ['ID', "NOM D'UTILISATEUR", 'Téléphone', 'ADERRSE EMAIL', 'ACTIF', "DATE DE CREATION", 'ACTION'];

export const OpsList = ['ID', "NOM DE L'UTILISATEUR", 'ADRESSE', 'Téléphone MOBILE', 'ADRESSE EMAIL 1', 'OPS SUP', "DATE DE CREATION", 'ACTIF', 'ACTION'];

export const CodeFacturationList = ['ID', 'LIBELLE', 'DESCRIPTION', 'MONTANT HT', 'FUEL MT', 'TVA', 'ACTION'];

export const TrackingTemplateList = ['N°', 'TEMPLATE', 'PARIS', 'MEDICALS', 'SERVICES', 'CHAUFFEUR', "DATE AJOUT "/* ,'ACTION' */];

export const Fuel = ['ID', 'VALEUR', 'POUR VARIATION', 'DATE DE CREATION', 'ACTIF', 'ACTION'];

export const Variation = ['ID', 'VALEUR'];

export const Chauffeurs = ['ID', "NOM D'UTILISATEUR", 'EMAIL', 'ADRESSE', 'Téléphone', "DATE DE CREATION", 'ACTIF', 'ACTION'];

// pour historiques de connexion
export const HistoriqueConnexion = ['ID', "NOM D'UTILISATEUR", 'DERNIERE CONNEXION', 'DERNIERE DECONNEXION', "DATE DE CREATION COMPTE", 'ACTIF', 'ACTION'];

export const Vehicules = ['ID', "MATRICULE", 'CHAUFFEUR', 'KM DE DEPART', 'KM DE RETOUR ', "DATE DE CREATION ", 'ACTION'];


