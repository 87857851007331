import axios from "axios";
import TokenService from "../config/tokenService.js";

class UserService {
  constructor() {
    TokenService.setHeader();
  }

  getUserData() {
    return axios.get(`${process.env.REACT_APP_API_URL}/user/me`);
  }

  allUser() {
    return axios.get(`${process.env.REACT_APP_API_URL}/user/all`);
  }

  userRole(id) {
    return axios.get(`${process.env.REACT_APP_API_URL}/user/role/${id}`);
  }

  // admin
  adminGetUserList(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/users`, data);
  }

  create(User) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/user/create`,
      User
    );
  }

  update(User) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/user`,
      User
    );
  }

  updateUser(User) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/user/update`,
      User
    );
  }

  updateUserByOps(User) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/user/update`,
      User
    );
  }

  activer(User) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/user/active`,
      User
    );
  }

  desactiver(User) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/user/desactiver`,
      User
    );
  }

  connectAccount(User) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/user/connect`,
      User
    );
  }


  sendQuote(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/user/calculatrice`, data)
  }


  countriesByQote(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/user/calculatrice/list/country/eurozone`, data)
  }

  LastInformation() {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/user/banniere/show-lastbanniere`
    );
  }

  fetchInformation() {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/user/setting/list`
    );
  }
}

export const userService = new UserService();
