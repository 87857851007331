import ClientService from "../../../services/client/clientService.js";

export const AdminClientTableDataController = async (nbrPage, action, filter) => {

    switch (action) {
        case "list":
            try {
                const data = await ClientService.fetchClients({
                    type: (filter ? (filter.type ?? "all") : "all"),
                    per_page: process.env.REACT_APP_DATA_PER_PAGE,
                    page: nbrPage,
                });
                let response = data.data;
                return {
                    success: true,
                    data: response,
                };
            } catch (error) {
                return {
                    success: false,
                    data: error,
                };
            }
        case "update":
            break;
    }
};
