import adminService from "../../../services/admin/adminService";

export const TrackinTemplate = async (nbrPage,type) => {
    try {
        let filter = {
            type: type,
            per_page: process.env.REACT_APP_DATA_PER_PAGE,
            page: nbrPage,
            filter: { other: "f" },
        };
        const data = await adminService.getTracking(filter);
        let response = data.data;

        return {
            success: true,
            data: response,
        };
    } catch (error) {
        return {
            success: false,
            data: error,
        };
    }
};
