export const TableEntete = (space) => {

  if (space === "medical") {
    return [
      { label: 'REF', filterKey: 'ref' },
      { label: 'ref inter', filterKey: 'customer_ref' },
      { label: 'EXP', filterKey: 'expeditor.fullname' },
      { label: 'Code don', filterKey: 'code_donneur' },
      { label: 'adresse', filterKey: 'expeditor.address1' },
      { label: 'DEST', filterKey: 'receiver.fullname' },
      { label: 'Code rec', filterKey: 'code_receveur' },
      { label: 'adresse', filterKey: 'receiver.address1' },
      { label: 'Centre de Fact.', filterKey: 'billing_center.billing_center_name' },
      { label: 'TRACKING', filterKey: 'suivis_details' },
      { label: 'Information', filterKey: false },
      { label: 'ATTRIBUTION', filterKey: false },
      { label: 'ACTION', filterKey: false }
    ]
  } else {
    return [
      { label: 'REF', filterKey: 'ref' },
      { label: 'ref inter', filterKey: 'customer_ref' },
      { label: 'EXP', filterKey: 'expeditor.fullname' },
      { label: 'adresse', filterKey: 'expeditor.address1' },
      { label: 'DEST', filterKey: 'receiver.fullname' },
      { label: 'adresse', filterKey: 'receiver.address1' },
      { label: 'Centre de Fact.', filterKey: 'billing_center.billing_center_name' },
      { label: 'TRACKING', filterKey: 'suivis_details' },
      { label: 'Information', filterKey: false },
      { label: 'ATTRIBUTION', filterKey: false },
      { label: 'ACTION', filterKey: false }
    ]
  }

}



const filterKey = (index, exnetSpace) => {
  if (exnetSpace !== 'medical') {
    if (index === 0) {
      return 'ref';
    } else if (index === 1) {
      return 'pickup_time';
    } else if (index === 2) {
      return 'expeditor.fullname';
    }
    else if (index === 3) {
      return 'expeditor.address1';
    }
    else if (index === 4) {
      return 'expeditor.country';
    }
    else if (index === 5) {
      return 'receiver.fullname';
    } else if (index === 6) {
      return 'receiver.address1';
    }
    else if (index === 7) {
      return 'receiver.country';
    } else if (index === 8) {
      return 'suivis_details';
    }
  } else {

    if (index === 0) {
      return 'ref';
    }
    else if (index === 1) {
      return 'pickup_time';
    }
    else if (index === 2) {
      return 'expeditor.fullname';
    }
    else if (index === 3) {
      return 'code_donneur';
    }
    else if (index === 4) {
      return 'expeditor.address1';
    }
    else if (index === 5) {
      return 'expeditor.country';
    }
    else if (index === 6) {
      return 'receiver.fullname';
    }
    else if (index === 7) {
      return 'code_receveur';
    }
    else if (index === 8) {
      return 'receiver.address1';
    }
    else if (index === 9) {
      return 'receiver.country';
    }
    else if (index === 10) {
      return 'suivis_details';
    }
  }

}
