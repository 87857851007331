export default [
    {
        index: 0,
        custom: false,
        key: "id",
        type: "field",
    },
    {
        index: 1,
        custom: false,
        key: "billing_center_name",
        type: "field",
    },
    {
        index: 2,
        custom: true,
        expression: {
            value: "{type}",
            type: "espace",
        },
        key: "",
        type: "field",
    },
    {
        index: 3,
        custom: false,
        key: "customer_id",
        type: "field",
    },
    {
        index: 4,
        custom: false,
        key: "country",
        type: "field",
    },
    {
        index: 5,
        custom: true,
        expression: {
            value: "{address}",
            type: "string",
        },
        key: "",
        type: "field",
        empty: {
            html: 'Aucune adresse'
        }
    },
    {
        index: 6,
        custom: false,
        key: "city",
        type: "field",
    },
    {
        index: 7,
        custom: true,
        expression: {
            value: "{email}",
            type: "string",
        },
        key: "",
        type: "field",
        empty: {
            html: 'Mail non renseigné'
        }
    },
    {
        index: 7,
        custom: true,
        expression: {
            value: "{telephone}",
            type: "string",
        },
        key: "",
        type: "field",
        empty: {
            html: 'Aucune telephone'
        }
    },
    {
        index: 9,
        custom: false,
        key: "customer_id",
        type: "field",
    },
    {
        index: 10,
        custom: true,
        expression: {
            value: "{created_at}",
            type: "date",
            format: {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
            },
        },
        key: "",
        type: "field",
    },
    {
        index: 11,
        custom: true,
        expression: {
            value: "{is_actif}",
            type: "radio",
        },
        key: "",
        type: "field",
    },
    {
        index: 12,
        custom: true,
        option: [
            {
                text: "Editer",
                icon: "fa fa-eye",
                action: "click",
                parameter: "ref",
            },
        ],
        key: "",
        type: "option",
    },
]
