// create Fuel react service

import axios from "axios";
import TokenService from "../config/tokenService.js";

class FuelService {

    constructor() {
        TokenService.setHeader();
    }
    fetchFuels(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/fuels`, data);
    }

    fetchFuelById(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/fuel`, data);
    }

    addFuel(fuel) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/fuel/create`, fuel);
    }
    editFuel(fuel) {
        return axios.put(`${process.env.REACT_APP_API_URL}/admin/fuel`, fuel);
    }
    toggleActivation(fuel) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/fuel/active/desactive`, fuel);
    }

}

export default new FuelService();
