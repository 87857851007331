export const countriesList = [
    { name: "Afghanistan", code: "AF" },
    { name: "Albanie", code: "AL" },
    { name: "Algérie", code: "DZ" },
    { name: "Samoa américaines", code: "AS" },
    { name: "Andorre", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctique", code: "AQ" },
    { name: "Antigua-et-Barbuda", code: "AG" },
    { name: "Argentine", code: "AR" },
    { name: "Arménie", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australie", code: "AU" },
    { name: "Autriche", code: "AT" },
    { name: "Azerbaïdjan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahreïn", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbade", code: "BB" },
    { name: "Bélarus", code: "BY" },
    { name: "Belgique", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Bénin", code: "BJ" },
    { name: "Bermudes", code: "BM" },
    { name: "Bhoutan", code: "BT" },
    { name: "Bolivie", code: "BO" },
    { name: "Bonaire, Saint-Eustache et Saba", code: "BQ" },
    { name: "Bosnie-Herzégovine", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Île Bouvet", code: "BV" },
    { name: "Brésil", code: "BR" },
    { name: "Territoire britannique de l'océan Indien", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgarie", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cabo Verde", code: "CV" },
    { name: "Cambodge", code: "KH" },
    { name: "Cameroun", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Îles Caïmans", code: "KY" },
    { name: "République centrafricaine", code: "CF" },
    { name: "Tchad", code: "TD" },
    { name: "Chili", code: "CL" },
    { name: "Chine", code: "CN" },
    { name: "Île Christmas", code: "CX" },
    { name: "Îles Cocos (Keeling)", code: "CC" },
    { name: "Colombie", code: "CO" },
    { name: "Comores", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "République démocratique du Congo", code: "CD" },
    { name: "Îles Cook", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: "Côte d'Ivoire", code: "CI" },
    { name: "Croatie", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Curaçao", code: "CW" },
    { name: "Chypre", code: "CY" },
    { name: "République tchèque", code: "CZ" },
    { name: "Danemark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominique", code: "DM" },
    { name: "République dominicaine", code: "DO" },
    { name: "Équateur", code: "EC" },
    { name: "Égypte", code: "EG" },
    { name: "Salvador", code: "SV" },
    { name: "Guinée équatoriale", code: "GQ" },
    { name: "Érythrée", code: "ER" },
    { name: "Estonie", code: "EE" },
    { name: "Éthiopie", code: "ET" },
    { name: "Îles Falkland (Malouines)", code: "FK" },
    { name: "Îles Féroé", code: "FO" },
    { name: "Fidji", code: "FJ" },
    { name: "Finlande", code: "FI" },
    { name: "France", code: "FR" },
    { name: "Guyane française", code: "GF" },
    { name: "Polynésie française", code: "PF" },
    { name: "Terres australes françaises", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambie", code: "GM" },
    { name: "Géorgie", code: "GE" },
    { name: "Allemagne", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Grèce", code: "GR" },
    { name: "Groenland", code: "GL" },
    { name: "Grenade", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernesey", code: "GG" },
    { name: "Guinée", code: "GN" },
    { name: "Guinée-Bissau", code: "GW" },
    { name: "Guyane", code: "GY" },
    { name: "Haïti", code: "HT" },
    { name: "Îles Heard et McDonald", code: "HM" },
    { name: "Saint-Siège (État de la Cité du Vatican)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hongrie", code: "HU" },
    { name: "Islande", code: "IS" },
    { name: "Inde", code: "IN" },
    { name: "Indonésie", code: "ID" },
    { name: "Iran, République islamique d'", code: "IR" },
    { name: "Irak", code: "IQ" },
    { name: "Irlande", code: "IE" },
    { name: "Île de Man", code: "IM" },
    { name: "Israël", code: "IL" },
    { name: "Italie", code: "IT" },
    { name: "Jamaïque", code: "JM" },
    { name: "Japon", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordanie", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Corée, République populaire démocratique de", code: "KP" },
    { name: "Corée, République de", code: "KR" },
    { name: "Koweït", code: "KW" },
    { name: "Kirghizistan", code: "KG" },
    { name: "République démocratique populaire lao", code: "LA" },
    { name: "Lettonie", code: "LV" },
    { name: "Liban", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Libéria", code: "LR" },
    { name: "Libye", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lituanie", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Macédoine, l'ex-République yougoslave de", code: "MK" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaisie", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malte", code: "MT" },
    { name: "Îles Marshall", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritanie", code: "MR" },
    { name: "Maurice", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexique", code: "MX" },
    { name: "Micronésie, États fédérés de", code: "FM" },
    { name: "Moldavie, République de", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolie", code: "MN" },
    { name: "Monténégro", code: "ME" },
    { name: "Montserrat", code: "MS" },
    { name: "Maroc", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibie", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Népal", code: "NP" },
    { name: "Pays-Bas", code: "NL" },
    { name: "Nouvelle-Calédonie", code: "NC" },
    { name: "Nouvelle-Zélande", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigéria", code: "NG" },
    { name: "Niué", code: "NU" },
    { name: "Île Norfolk", code: "NF" },
    { name: "Îles Mariannes du Nord", code: "MP" },
    { name: "Norvège", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palaos", code: "PW" },
    { name: "Palestine, État de", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papouasie-Nouvelle-Guinée", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Pérou", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Pologne", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Porto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Réunion", code: "RE" },
    { name: "Roumanie", code: "RO" },
    { name: "Russie", code: "RU" },
    { name: "Rwanda", code: "RW" },
    { name: "Saint-Barthélemy", code: "BL" },
    { name: "Sainte-Hélène, Ascension et Tristan da Cunha", code: "SH" },
    { name: "Saint-Christophe-et-Niévès", code: "KN" },
    { name: "Sainte-Lucie", code: "LC" },
    { name: "Saint-Martin (partie française)", code: "MF" },
    { name: "Saint-Pierre-et-Miquelon", code: "PM" },
    { name: "Saint-Vincent-et-les Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "Saint-Marin", code: "SM" },
    { name: "Sao Tomé-et-Principe", code: "ST" },
    { name: "Arabie saoudite", code: "SA" },
    { name: "Sénégal", code: "SN" },
    { name: "Serbie", code: "RS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapour", code: "SG" },
    { name: "Sint Maarten (partie néerlandaise)", code: "SX" },
    { name: "Slovaquie", code: "SK" },
    { name: "Slovénie", code: "SI" },
    { name: "Îles Salomon", code: "SB" },
    { name: "Somalie", code: "SO" },
    { name: "Afrique du Sud", code: "ZA" },
    { name: "Géorgie du Sud-et-les Îles Sandwich du Sud", code: "GS" },
    { name: "Soudan du Sud", code: "SS" },
    { name: "Espagne", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Soudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard et Jan Mayen", code: "SJ" },
    { name: "Eswatini", code: "SZ" },
    { name: "Suède", code: "SE" },
    { name: "Suisse", code: "CH" },
    { name: "République arabe syrienne", code: "SY" },
    { name: "Taiwan, Province de Chine", code: "TW" },
    { name: "Tadjikistan", code: "TJ" },
    { name: "Tanzanie, République-Unie de", code: "TZ" },
    { name: "Thaïlande", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinité-et-Tobago", code: "TT" },
    { name: "Tunisie", code: "TN" },
    { name: "Turquie", code: "TR" },
    { name: "Turkménistan", code: "TM" },
    { name: "Îles Turks et Caïques", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Ouganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "Émirats arabes unis", code: "AE" },
    { name: "Royaume-Uni", code: "GB" },
    { name: "États Unis D'Amérique", code: "US" },
    { name: "Îles mineures éloignées des États-Unis", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Ouzbékistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela, République bolivarienne du", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Îles Vierges britanniques", code: "VG" },
    { name: "Îles Vierges des États-Unis", code: "VI" },
    { name: "Wallis-et-Futuna", code: "WF" },
    { name: "Sahara occidental", code: "EH" },
    { name: "Yémen", code: "YE" },
    { name: "Zambie", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" }
];



export const getCountryNameOrCode = (countryName, code = false) => {
    if (countryName?.length === 2) {
        const country = countriesList.find((c) => c.code?.toUpperCase() === countryName?.toUpperCase());
        return country ? country.name : null;
    } else if (code) {
        const country = countriesList.find((c) => c.name?.toUpperCase() === countryName?.toUpperCase());
        return country ? country.code.toLowerCase() : null;
    }
    else {
        return countryName
    }

}

export const countriesEU = [
    { iso: 'DE', name: 'Allemagne' },
    { iso: 'AT', name: 'Autriche' },
    { iso: 'BE', name: 'Belgique' },
    { iso: 'BG', name: 'Bulgarie' },
    { iso: 'CY', name: 'Chypre' },
    { iso: 'HR', name: 'Croatie' },
    { iso: 'DK', name: 'Danemark' },
    { iso: 'ES', name: 'Espagne' },
    { iso: 'EE', name: 'Estonie' },
    { iso: 'FI', name: 'Finlande' },
    { iso: 'FR', name: 'France' },
    { iso: 'GR', name: 'Grèce' },
    { iso: 'HU', name: 'Hongrie' },
    { iso: 'IE', name: 'Irlande' },
    { iso: 'IT', name: 'Italie' },
    { iso: 'LV', name: 'Lettonie' },
    { iso: 'LT', name: 'Lituanie' },
    { iso: 'LU', name: 'Luxembourg' },
    { iso: 'MT', name: 'Malte' },
    { iso: 'NL', name: 'Pays-Bas' },
    { iso: 'PL', name: 'Pologne' },
    { iso: 'PT', name: 'Portugal' },
    { iso: 'CZ', name: 'République tchèque' },
    { iso: 'RO', name: 'Roumanie' },
    { iso: 'SI', name: 'Slovénie' },
    { iso: 'SK', name: 'Slovaquie' },
    { iso: 'SE', name: 'Suède' },
];


export const holydayDate = [
    "01/01",
    "01/04",
    "01/05",
    "08/05",
    "09/05",
    "20/05",
    "14/07",
    "15/08",
    "01/11",
    "11/11",
    "25/12"
];