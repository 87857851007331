export default [
    {
        index: 0,
        custom: false,
        key: "id",
        type: "field",
    },
    {
        index: 1,
        custom: false,
        key: "label",
        type: "field",
    },
    {
        index: 3,
        custom: true,
        expression: {
            value: "{description}",
            type: "string",
        },
        key: "",
        type: "field",
        empty: {
            html: 'Aucune description'
        }
    },
    {
        index: 4,
        custom: true,
        expression: {
            value: "{montant}",
            type: "string",
        },
        key: "",
        type: "field",
        empty: {
            html: 'Aucun montant'
        }
    },
    {
        index: 5,
        custom: true,
        expression: {
            value: "{fuel.value}",
            type: "string",
        },
        key: "",
        type: "field",
        empty: {
            html: 'Aucune address'
        }
    },
    {
        index: 6,
        custom: true,
        expression: {
            value: "{created_at}",
            type: "date",
            format: {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
            },
        },
        key: "",
        type: "field",
    },
    {
        index: 7,
        custom: true,
        expression: {
            value: "{is_actif}",
            type: "radio",
        },
        key: "",
        type: "field",
    },
    {
        index: 8,
        custom: true,
        option: [
            {
                text: "Editer",
                icon: "fa fa-eye",
                action: "click",
                parameter: "ref",
            },
        ],
        key: "",
        type: "option",
    },
]
