import { create } from 'zustand'

const initialState = {
    field: '',
    message: '',
    state: '',
    position: 0
}

export const ErrorStore = create((set) => ({
    errorField: { ...initialState },
    setMessageError: (newErroeMessage) => set((state) => ({
        errorField: { ...newErroeMessage }
    })),
    resetErrorField: () => set((state) => ({
        errorField: { ...initialState }
    }))
}))