// create Client react service

import axios from "axios";
import TokenService from "../config/tokenService.js";

class ClientService {
  constructor() {
    TokenService.setHeader();
  }
  fetchClients(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/clients`, data);
  }

  fetchClientById(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/client`, data);
  }

  desactiverClientById(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/client/desactiver`,
      data
    );
  }

  activerClientById(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/client/activer`,
      data
    );
  }

  addClient(Client) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/client/create`,
      Client
    );
  }

  editClient(Client) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/client/edit`,
      Client
    );
  }

  getsPackageType(data,role) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/${role==="client"?"user":"ops"}/emballage/type/list`,
      data
    );
  }
  getPackages(data,role) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/${role==="client"?"user":"ops"}/emballage/list`,
      data
    );
  }
  getsDiagnobag(data,role) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/${role==="client"?"user":"ops"}/type-diagnobag/list`,
      data
    );
  }

  getsSonde(data,role) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/${role==="client"?"user":"ops"}/type-sonde/list`,
      data
    );
  }

  getPackagesByTypeId(data,role) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/${role==="client"?"user":"ops"}/emballage/type/list-emballage`,
      data
    );
  }
}

export default new ClientService();
