import ExpeditionService from "../../services/expedition/ExpeditionService";
import { expeditionTable, stateEXpedition, typeTable_2 } from "../../utils/SpaceData";
import { LinkData } from "../../utils/Functions/otherFunctions.js";
import { AdminClientTableDataController } from "./client/adminClientTableDataController.js";
import {
    AdminCentreFacturationTableDataController
} from "./centreFacturation/adminCentreFacturationTableDataController.js";
import { AdminUserTableDataController } from "./user/adminUserTableDataController.js";
import { TrackinTemplate } from "./trackingTemplate/TrackingTemplate";
import { AdminFuelTableDataController } from "./facture/adminFuelTableDataController.js";
import { AdminFacturationLinesTableDataController } from "./facture/adminFacturationLinesTableDataController.js";
import { AdminVehiculesTableDataController } from "./vehicule/adminVehiculeTableDataController.js";
import { AdminHistoriqueTableDataController } from "./historique/adminHistoriqueTableDataController.js";

export const AdminTableDataController = async (dataType, nbrPage, lien, process, filter) => {
    let { role, espace, typeExpedition, etatExpedition } = LinkData(lien);


    switch (dataType) {
        case "centre_facturation":
            return await AdminCentreFacturationTableDataController(nbrPage, process, filter);
        case "clients":
            return await AdminClientTableDataController(nbrPage, process, filter);
        case "utilisateurs":
            return await AdminUserTableDataController(dataType, nbrPage, process, filter);
        case "ops":
            return await AdminUserTableDataController(dataType, nbrPage, process, filter);
        case "administrateurs":
            return await AdminUserTableDataController(dataType, nbrPage, process, filter);
        case "historique":
            return await AdminHistoriqueTableDataController(nbrPage, process);
        case "vehicules":
            return await AdminVehiculesTableDataController(nbrPage, process);
        case "chauffeurs":
            return await AdminUserTableDataController(dataType, nbrPage, process, filter);
        case "expeditions":
            break;
        case "contacts":
            break;
        case "statistique_client":
            break;
        case "statistique_factures":
            break;
        case "statistique_expeditions":
            break;
        case "facture_fuel":
            return await AdminFuelTableDataController(nbrPage, process, filter);
        case "facture_code_facturation":
            return await AdminFacturationLinesTableDataController(nbrPage, process, filter);
        case "tracking_template":
            return await TrackinTemplate(nbrPage, 'paris')
            break;
        case "fuel":
            break;
    }
};
