import React, { useEffect } from "react";

import { Switch } from "@headlessui/react";
import clsx from "clsx";
import { CapacitySelector, DiagoBagSelector, EmballageTypeSelector, SondeSelector } from "../../emballage/emballageTypeSelector";
import InputComponent from "../../InputComponent/InputComponent";
import { ErrorStore } from "../../../store/inputError";
import { useAppContext } from "../../../context";
import { ShippingDetails } from "../../../store/shippingDetails";
import { EmballageDataStore } from "../../../states/tableData";

export const EmballageUpdate = ({ data, index }) => {
  const setEmballage = ShippingDetails((state) => state.setEmballage);
  const updateEmballage = ShippingDetails((state) => state.updateEmballage);
  const emballage = ShippingDetails((state) => state.details.emballage);
  const setPackage = ShippingDetails((state) => state.setPackage);
  const errorMessage = ErrorStore((state) => state.errorField);
  const setMessageError = ErrorStore((state) => state.setMessageError);
  const selectorListData = EmballageDataStore(
    (state) => state.selectorListData
  );




  useEffect(() => {
    setEmballage({
      temperature: data?.emballage?.type_emballage_id,
      gamme_emballage: data?.emballage?.id,
      sonde: data?.type_sonde_id,
      diagnobags: data?.type_diagnobag_id,
      nbr_diagnobag: data?.nbr_diagnobag,
      has_diagnobag: data?.has_diagnobag,
      has_sonde: data?.has_sonde,
    });
  }, [data]);

  const updatePackage = (value) => {
    let data = selectorListData.find((item) => item.value === value);

    let newValue = {
      length: data.dimensions_exterieures_longueur,
      width: data.dimensions_exterieures_largeur,
      height: data.dimensions_exterieures_hauteur,
      weight: data.poids,
      weight_volume:
        (data.dimensions_exterieures_longueur *
          data.dimensions_exterieures_largeur *
          data.dimensions_exterieures_hauteur) /
        5000,
    };

    setPackage({ ...newValue });

  }

  return (
    <section className={"content px-1"}>
      <div className={"grid grid-cols-2 gap-4 space-y-2 md:space-y-0 mx-1 "}>
        {/*  Sélectionner la température de votre expédition */}
        <EmballageTypeSelector
          label="Température souhaitée"
          modifyData={(value) => {
            updateEmballage("temperature", value);
          }}
          errorInformation={"errorInformation"}
          // value={data?.emballage?.type_emballage_id}
          value={emballage?.temperature}
        />

        <CapacitySelector
          label="Type d'emballage"
          modifyData={(value) => {
            updatePackage(value);
            updateEmballage("gamme_emballage", value)
          }}
          errorInformation={"errorInformation"}
          // value={data?.emballage?.id}
          value={emballage?.gamme_emballage}
          // emballageType={data?.emballage?.type_emballage_id}
          emballageType={emballage?.temperature}
          isMulti={false}
        />


        <div>
          <SwitchComponent
            value={emballage?.has_sonde}
            onUpdate={(value) => updateEmballage("has_sonde", value)}
            title="Souhaitez-vous une sonde de température?"
          />

          {(emballage?.has_sonde) && (
            <SondeSelector
              label="Type de sonde"
              modifyData={(value) => updateEmballage("sonde", value)}
              errorInformation={"errorInformation"}
              value={emballage?.sonde}
            />
          )}
        </div>

        <div>
          <SwitchComponent
            value={emballage?.has_diagnobag}
            onUpdate={(value) => updateEmballage("has_diagnobag", value)}
            title="Souhaitez-vous des diagnobags supplémentaires?"
          />

          {(emballage?.has_diagnobag) && (
            <div className="grid-cols-3 gap-3 md:grid">
              <div className="col-span-2">
                <DiagoBagSelector
                  label="Type de diagnobags"
                  modifyData={(value) =>
                    updateEmballage("diagnobags", value)
                  }
                  errorInformation={"errorInformation"}
                  value={emballage?.diagnobags}
                />
              </div>
              <div>
                <label
                  htmlFor="diagnobagsNb"
                  className="block mb-1 text-lg font-normal leading-6"
                >
                  Quantité
                </label>
                <InputComponent
                  type="number"
                  value={emballage?.nbr_diagnobag}
                  onChange={(value) =>

                    updateEmballage("nbr_diagnobag", value)
                  }
                  label="Nombre de diagnobags"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};


const SwitchComponent = ({ value, onUpdate, title }) => {
  const { isAuth, setIsAuth } = useAppContext();

  const backShipping = () => {
    onUpdate(!value);
  };

  return (
    <div className="flex items-center justify-between my-2">
      <span className="text-lg font-bold">{title}</span>
      <div className="flex items-center">
        <span className="text-lg font-semibold">Non</span>
        <Switch
          checked={value}
          onChange={backShipping}
          className={clsx(
            {
              "bg-paris": isAuth?.spaceName === "paris" && value,
              "bg-medical ": isAuth?.spaceName === "medical" && value,
              "bg-service ": isAuth?.spaceName === "service" && value,
              "bg-admin ": isAuth?.spaceName === "admin" && value,
            },
            "group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out mx-3",

            value ? "bg-blue-500" : "bg-red-900",
            "focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            className={clsx(
              "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              value ? "translate-x-5" : "translate-x-0"
            )}
          >
            <span
              aria-hidden="true"
              className={clsx(
                "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in",
                value ? "opacity-0 duration-100 ease-out" : "opacity-100"
              )}
            >
              <svg
                fill="none"
                viewBox="0 0 12 12"
                className={clsx(
                  {
                    "text-paris": isAuth?.spaceName === "paris",
                    "text-medical": isAuth?.spaceName === "medical",
                    "text-service": isAuth?.spaceName === "service",
                    "text-admin": isAuth?.spaceName === "admin",
                  },
                  "w-3 h-3 my-1"
                )}
              >
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span
              aria-hidden="true"
              className={clsx(
                "absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out",
                value ? "opacity-100 duration-200 ease-in" : "opacity-0"
              )}
            >
              <svg
                fill="currentColor"
                viewBox="0 0 12 12"
                className={clsx(
                  {
                    "text-paris": isAuth?.spaceName === "paris",
                    "text-medical": isAuth?.spaceName === "medical",
                    "text-service": isAuth?.spaceName === "service",
                    "text-admin": isAuth?.spaceName === "admin",
                  },
                  "w-3 h-3"
                )}
              >
                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
              </svg>
            </span>
          </span>
        </Switch>
        <span className="text-lg font-semibold">Oui</span>
      </div>
    </div>
  );
};
