import { useParams } from "react-router-dom";
import ExpeditionDetailsPage from "../Pages/dashboard/ExpeditionDetails.page.jsx";
import NewShippingPage from "../Pages/dashboard/newShipping.page.jsx";
import ProfilePage from "../Pages/dashboard/profile.page.jsx";
import ContactIndex from "../components/Tableaux/Contacts/ContactIndex.jsx";
import { ArchivedExpedition, DeliveredExpedition, InProgressExpedition } from "../components/Tableaux/ExpeditionsClient/IndexClient.jsx";
import Tchatcomponent from "../components/tchatComponent/opsTchatcomponent.jsx";
import { StatsCourses, StatsFreight } from "../components/Admin/Stats/Index.jsx";
import CalculatriceComponent from "../components/Calculatrice/CalculatriceComponent.jsx";
import { CreateShhing } from "../components/shipping/Index.jsx";

const InProgress = ({ route, title, subtitle, subRoute = false }) => {
  let { ExpeditionType, identifiant } = useParams();
  switch (ExpeditionType) {
    case "courses-a-courses":
      ExpeditionType = "course express";
      break;
  }
  title = ExpeditionType
    ? title + " - " + ExpeditionType.replaceAll("-", " ")
    : title;
  return (
    <InProgressExpedition
      subtitle={subtitle}
      title={title}
      category={`${ExpeditionType === "import" || ExpeditionType === "export"
        ? ExpeditionType
        : "all"
        }`}
      type_expedition={`${ExpeditionType === "courses-a-courses" ? "course" : "expedition"
        }`}
      route={route}
      subRoute={subRoute ? subRoute : "en_cours"}
    />
  );
};

const Delivered = ({ route, title, subtitle }) => {
  let { ExpeditionType, identifiant } = useParams();
  switch (ExpeditionType) {
    case "courses-a-courses":
      ExpeditionType = "course express";
      break;
  }
  title = ExpeditionType
    ? title + " - " + ExpeditionType.replaceAll("-", " ")
    : title;
  return (
    <DeliveredExpedition
      subtitle={subtitle}
      title={title}
      category={`${ExpeditionType === "import" || ExpeditionType === "export"
        ? ExpeditionType
        : "all"
        }`}
      type_expedition={`${ExpeditionType === "courses-a-courses" ? "course" : "expedition"
        }`}
      route={route}
    />
  );
};

const Archived = ({ route, title, subtitle, title2 = false }) => {
  let { ExpeditionType, identifiant } = useParams();
  switch (ExpeditionType) {
    case "courses-a-courses":
      ExpeditionType = "course express";
      break;
  }
  let valeur = title2 ? title2 : ExpeditionType.replaceAll("-", " ")
  title = ExpeditionType
    ? title + " - " + valeur
    : title;
  return (
    <ArchivedExpedition
      subtitle={subtitle}
      title={title}
      category={`${ExpeditionType === "import" || ExpeditionType === "export"
        ? ExpeditionType
        : "all"
        }`}
      type_expedition={`${ExpeditionType === "courses-a-courses" ? "course" : "expedition"
        }`}
      route={route}
    />
  );
};

const ContactList = () => {
  return (
    <ContactIndex
      role={"client"}
      expeditionKind={"carnet"}
      title={"Dashboard"}
    />
  );
};

export const customerRouter = [
  {
    path: "",
    element: (
      // <ExpeditionsClientView
      //   category={"all"}
      //   type_expedition={"expedition"}
      //   route="all"
      //   title={"Expéditions en cours - Dashboard"}
      //   subtitle={"Liste de vos expéditions en cours"}
      //   subRoute={false}
      // />
      <InProgress
        route={"all"}
        title={"Expéditions en cours - Dashboard"}
        subtitle={"Liste de vos expéditions en cours"}
      />
    ),
  },
  {
    path: "calculatrice",
    element: (
      <CalculatriceComponent />
    ),
  },
  {
    path: "livre",
    element: (
      <InProgress
        route={"all"}
        subRoute='livrer'
        title={"Expéditions livrées - Dashboard"}
        subtitle={"Liste de vos expéditions livrées"}
      />
    ),
  },
  {
    path: "detail/:identifiant",
    element: <ExpeditionDetailsPage />,
  },
  {

    path: "statiques/expeditions",
    element: <StatsCourses author={'client'} />,
  },
  {
    path: "statiques/freight",
    element: <StatsFreight author={'client'} />,
  },
  {
    path: "message",
    element: <Tchatcomponent />,
  },
  {
    path: "message/:identifiant",
    element: <Tchatcomponent />,
  },
  {
    path: "carnet",
    element: <ContactList role={"client"} title={"Dashboard"} />,
  },
  {
    path: ":ExpeditionType",
    element: (
      <InProgress
        route={"en-cours"}
        title={"Expéditions en cours"}
        subtitle={"Liste de vos expéditions "}
      />
    ),
  },

  {
    path: ":ExpeditionType/en-cours",
    element: (
      <InProgress
        route={"en-cours"}
        title={"Expéditions  en cours"}
        subtitle={"Liste de vos expéditions"}
      />
    ),
  },
  {
    path: ":ExpeditionType/livre",
    element: (
      <Delivered
        route={"livrees"}
        title={"Expéditions livrées"}
        subtitle={"Liste de vos expéditions "}
      />
    ),
  },
  {
    path: ":ExpeditionType/archive",
    element: (
      <Archived
        route={"archivees"}
        title={"Expéditions archivées"}
        subtitle={"Liste de vos expéditions "}
        title2="Archivees"
      />
    ),
  },
  {
    path: ":ExpeditionType/en-cours/:identifiant",
    element: <ExpeditionDetailsPage />,
  },
  {
    path: ":ExpeditionType/livre/:identifiant",
    element: <ExpeditionDetailsPage />,
  },
  {
    path: ":ExpeditionType/archive/:identifiant",
    element: <ExpeditionDetailsPage />,
  },
  {

    path: ":ExpeditionType/message/:identifiant",
    element: <Tchatcomponent />,
  },
  {
    path: ":ExpeditionType/new",
    element: <CreateShhing /> //<NewShippingPage />//
  },
  {
    path: ":ExpeditionType/:identifiant",
    element: <ExpeditionDetailsPage />,
  },

  {
    path: "profile",
    element: <ProfilePage />,
  },

];
