import axios from "axios";
import TokenService from "../config/tokenService.js";

class AdminPackageService {
    constructor() {
        TokenService.setHeader();
    }

    addPackageType(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/emballage/type/create`, data);
    }

    getsPackageType(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/emballage/type/list`, data);
    }

    updatePackageType(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/emballage/type/update`, data);
    }

    changePackageType(id,state){
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/emballage/type/${state}`,{id});
    }

    getPackageType(id){
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/emballage/type/show`,{id});
    }

    // 


    getPackages(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/emballage/list`,data);
    }

    getPackagesByTypeIdUser(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/user/emballage/type/list-emballage`,data);
    }

    getPackagesById(id) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/emballage/show`,{id });
    }

    createPackage(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/emballage/create`, data);
    }

    changePackageState(id,state){
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/emballage/${state}`,{id});
    }

    updatePackage(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/emballage/update`, data);
    }


    // Diagnobag 

    getsDiagnobag(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/type-diagnobag/list`,data);
    }

    getDiagnobagById(id) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/type-diagnobag/show`,{id });
    }

    createDiagnobag(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/type-diagnobag/create`, data);
    }

    changeDiagnobagState(id,state){
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/type-diagnobag/${state}`,{id});
    }

    updateDiagnobag(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/type-diagnobag/update`, data);
    }


     // Sondes 

     getsSonde(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/type-sonde/list`,data);
    }

    getSondeById(id) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/type-sonde/show`,{id });
    }

    
    createSonde(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/type-sonde/create`, data);
    }

    changeSondeState(id,state){
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/type-sonde/${state}`,{id});
    }

    updateSonde(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/type-sonde/update`, data);
    }

}

export const adminPackageService = new AdminPackageService();