// create Client react service

import axios from "axios";
import TokenService from "../config/tokenService.js";

class ManifestClass {

    constructor() {
        TokenService.setHeader();
    }

    create(manifest) {
        return axios.post(`${process.env.REACT_APP_API_URL}/user/manifest/create`, manifest);
    }

    update(manifest) {
        return axios.post(`${process.env.REACT_APP_API_URL}/user/manifest/update`, manifest);
    }

    get(params) {
        return axios.post(`${process.env.REACT_APP_API_URL}/user/manifest`, params);
    }

    delete(id) {
        return axios.post(`${process.env.REACT_APP_API_URL}/user/manifest/delete`, { id });
    }
}

const ManifestService = new ManifestClass()

export default ManifestService;
