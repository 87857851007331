export const trierTableau = (tableau, critere, ordre) => {

    return tableau.sort((a, b) => {
        let tableauCritee = critere?.split('.')
        let valueA;
        let valueB;
       
        if (tableauCritee?.length > 1) {
                if (a[tableauCritee[0]][tableauCritee[1]] && b[tableauCritee[0]][tableauCritee[1]]) {
                    valueA = a[tableauCritee[0]][tableauCritee[1]];
                    valueB = b[tableauCritee[0]][tableauCritee[1]];
                } else {
                    valueA = a[tableauCritee[0]];
                    valueB = b[tableauCritee[0]];
                }

            }
            else {
                valueA = a[tableauCritee[0]];
                valueB = b[tableauCritee[0]];
            }

        if (ordre === 'asc') {
            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        } else if (ordre === 'desc') {
            if (valueA > valueB) {
                return -1;
            }
            if (valueA < valueB) {
                return 1;
            }
            return 0;
        }
    });
}




export const trierTableau1 = (tableau, critere, ordre) => {
    return tableau.sort((a, b) => {
        const tableauCritee = critere?.split('.');
        const valueA = (tableauCritee?.length > 1) ? a[tableauCritee[0]][tableauCritee[1]] : a[tableauCritee[0]];
        const valueB = (tableauCritee?.length > 1) ? b[tableauCritee[0]][tableauCritee[1]] : b[tableauCritee[0]];

        if (ordre === 'asc') {
            return (valueA < valueB) ? -1 : (valueA > valueB) ? 1 : 0;
        } else if (ordre === 'desc') {
            return (valueA > valueB) ? -1 : (valueA < valueB) ? 1 : 0;
        }

        // Default case if ordre is neither 'asc' nor 'desc'
        return 0;
    });
};
