// create Client react service

import axios from "axios";
import TokenService from "../config/tokenService.js";

class AdminService {
  constructor() {
    TokenService.setHeader();
  }

  create(Admin) {
    return axios.post(
      "" + `${process.env.REACT_APP_API_URL}/admin/user/admin/create`,
      Admin
    );
  }

  update(Admin) {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/admin/user/admin/${Admin.id}`,
      Admin
    );
  }

  dashboardData() {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/dashboard`);
  }

  statisticData(profil) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/users/dashboard`,
      { profil }
    );
  }

  getOpsTracking(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/ops/trackings`, data);
  }

  getTracking(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/trackings`, data);
  }

  createTracking(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/tracking/create`,
      data
    );
  }

  editTracking(id, data) {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/admin/traking/${id}`,
      data
    );
  }

  editTrackingState(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/tracking/update`,
      data
    );
  }

  createContact(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/contact/create`,
      data
    );
  }

  updateContact(data) {
    return axios.put(`${process.env.REACT_APP_API_URL}/admin/contact`, data);
  }

  getStatistiques(data, author) {
    if (author === "admin") {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/admin/statistic/shipping`,
        data
      );
    } else {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/user/statistics/shipping/user`,
        data
      );
    }
  }

  getAllDrivers(type) {
    return axios.post(`${process.env.REACT_APP_API_URL}/ops/all/driver`, {
      interface: type,
    });
  }

  getAllHistories(data) {
    //return axios.post(`${process.env.REACT_APP_API_URL}/ops/historique-action/listByfilter`,data);
    //return axios.post(`${process.env.REACT_APP_API_URL}/admin/historique-action/listByfilter`, data);
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/historique-action/list`,
      data
    );
  }

  getAllHistoriesByOps(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/historique-action/listByfilter`,
      data
    );
    // return axios.post(`${process.env.REACT_APP_API_URL}/admin/historique-action/listByfilter`, data);
    // return axios.post(`${process.env.REACT_APP_API_URL}/admin/historique-action/list`, data);
  }

  filterHistories(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/historique-action/ListbyfilterOption`,
      data
    );
  }

  allEmployees(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/salaries`, data);
  }

  UpdateInformation(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/banniere/update`,
      data
    );
  }

  newInformation(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/banniere/create`,
      data
    );
  }

  fetchInformation() {
    return axios.get(`${process.env.REACT_APP_API_URL}/user/banniere/list`);
  }

  LastInformation() {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/user/banniere/show-lastbanniere`
    );
  }

  getInfo(id) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/user/banniere/show-banniere`,{id}
    );
  }

}

export default new AdminService();
