import { create } from 'zustand'



export const ErrorStore = create((set) => ({
    errorField: {
        field: '',
        message: '',
        state: '',
        position:0
    },
    setMessageError: (newErroeMessage) => set((state) => ({
        errorField: { ...newErroeMessage }
    })),
}))