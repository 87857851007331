import axios from "axios";
import TokenService from "../config/tokenService.js";

class HistoriqueService {

    constructor() {
        TokenService.setHeader();
    }


    createHistorique(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/admin/historique/Connexion/create`,
            data
        );
    }

    updateHistorique(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/admin/historique/Connexion/update`,
            data
        );
    }

    listHistorique(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/admin/historique/Connexion/list`,
            data
        );
    }

    listHistoriqueByid(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/admin/historique/Connexion/voirbyid`,
            data
        );
    }

    listHistoriqueByUser(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/priseposte/listByUser`,
            data
        );
    }

}

export default new HistoriqueService();
