import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import contactService from '../services/contact/contactService';
import { Link } from 'react-router-dom';
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { toast } from 'react-toastify';
import templateXlsx from "./../assets/files/ExnetContactTemplate.xlsx";
import { ContactDataStore } from '../states/tableData';

const Data = ['civility', "company", "firstName", "lastName", "country", "address1", "address2", "postal_code", "city", "telephone1", "telephone2", "email1", "email2"];
export const ExcelReader = ({ setOpen }) => {
  const [files, setFiles] = useState([]);
  const dataList = ContactDataStore((state) => state.dataList)
  const setDataList = ContactDataStore((state) => state.setDataList);

  // Fonction pour lire le fichier Excel
  const handleFileUpload = (value) => {

    const file = value;

    // Vérifie si un fichier est sélectionné
    if (file) {
      const reader = new FileReader();
      const toastsId = toast.loading("Soumission en cours...", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      // Lecture du fichier Excel une fois chargé
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Sélectionne la première feuille
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const table = [];

        // Convertit les données de la feuille en JSON
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        jsonData?.slice(1).forEach((item, index) => {
          if (item[2] && item[2] !== "" && item[3] && item[3] !== "") {
            table.push({
              civility: item[0] ? item[0] : "",
              company: item[1] ? item[1] : "",
              firstName: item[2] ? item[2] : "",
              lastName: item[3] ? item[3] : "",
              fullname: `${item[2] ? item[2] : ""} ${item[3] ? item[3] : ""} `,
              country: item[4] ? item[4] : "",
              address1: item[5] ? item[5] : "",
              address2: item[6] ? item[6] : "",
              postal_code: item[7] ? "" + item[7] : "",
              city: item[8] ? item[8] : "",
              telephone1: item[9] ? "" + item[9] : "",
              telephone2: item[10] ? "" + item[10] : "",
              email1: item[11] ? item[11] : "",
              email2: item[12] ? item[12] : ""
            })
          }
        });

        const { data: { success, data: element, is_done, message }, } = await contactService.clientCreateContactUpload(table)

        if (success) {
          toast.update(toastsId, {
            render: message,
            type: "success",
            isLoading: false,
            autoClose: 1000,
            position: 'top-center'
          });
          setOpen()
          setDataList([...element,...dataList, element])

        } else {
          toast.update(toastsId, {
            position: 'top-center',
            render: "Erreur dans le fichier excel",
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
        }
      };

      reader.readAsArrayBuffer(file); // Lire le fichier en tant que tableau de bits
    }
  };

  useEffect(() => {
    if (files.length > 0) {
      handleFileUpload(files[0]['file'])
    }
  }, [files])


  return (
    <div>
      <h2 className={"text-center"}>Sauvegarde multiple</h2>

      <FilePond
        files={files}
        allowReorder={true}
        allowMultiple={false}
        onupdatefiles={setFiles}
        labelIdle='Faites glisser et déposez vos fichiers ou <span className="filepond--label-action">Parcourir</span>'
      />


      {/* Input pour sélectionner un fichier Excel */}
      <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} className='hidden' />
      <p className={"text-center  text-lg font-semibold "}>
        <a download={true} href={templateXlsx} className='text-red-500' >Cliquez ici pour télécharger le fichier modèle </a>
      </p>


    </div>
  );
};

