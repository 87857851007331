import React, { useEffect, useState } from 'react';
import Select from "react-select";


function DelaySelector({ type_de_roue, value, onShippingDataChange }) {

  const [dataList, setdataList] = useState([]);

  useEffect(() => {
    if (parseInt(type_de_roue) === 4) {
      setdataList([...delaisData4])
    } else {
      setdataList([...delaisData])
    }
  }, [type_de_roue])

  const selectedValue = dataList.find((item) => item.value === value);
  return (
    <div>
      <Select
        className="text-base"
        options={dataList}
        value={selectedValue}
        onChange={(v) => onShippingDataChange(v.value)}
        placeholder={"Temps DE LIVRAISON *".toUpperCase()}
      />
    </div>
  );
}

export default DelaySelector;

const delaisData = [
  { value: "normal", label: " Normal (3 heures )", time: 180 },
  { value: "spx_1", label: " SPX (45 mins  )", time: 45 },
  { value: "spx_2", label: " Sur rendez-vous", time: "rien" },
];

const delaisData4 = [
  { value: "spx_1", label: " SPX (1 heure 30 minutes )", time: 90 },
  { value: "normal", label: " Normal (3 heures )", time: 180 },
  { value: "spx_2", label: " Sur rendez-vous", time: "rien" },
];