// create Client react service

import axios from "axios";
import TokenService from "../config/tokenService.js";

class OpsService {
  constructor() {
    TokenService.setHeader();
  }

  create(Ops) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/user/ops/create`,
      Ops
    );
  }

  update(Ops) {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/admin/user/ops/${Ops.id}`,
      Ops
    );
  }

  addPod(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/pod/create`,
      data
    );
  }

  getPod(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/pod/get`,
      data
    );
  }

  updatePod(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/pod/update`,
      data
    );
  }

  deletePod(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/pod/delete`,
      data
    );
  }

  cancelPod(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/cancel`,
      data
    );
  }

  addInformations(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/important/add`,
      data
    );
  }

  restoreShipping(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/restore`,
      data
    );
  }

  updateShipping(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/update`,
      data
    );
  }

  addTracking(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/tracking/add`,
      data
    );
  }

  addComment(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/comment/add`,
      data
    );
  }

  updateComment(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/comment/update`,
      data
    );
  }

  deleteComment(commentId) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/comment/delete`,
      {
        comment_id: commentId,
      }
    );
  }

  addEmail(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/email/add`,
      data
    );
  }

  deleteEmail(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/email/delete`,
      data
    );
  }

  updateEmail(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/email/update`,
      data
    );
  }

  getFiles(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/file/list`,
      data
    );
  }

  addFiles(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/file/add`,
      data
    );
  }

  deleteFile(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/file/delete`,
      data
    );
  }

  updateInfos(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/info/update`,
      data
    );
  }

  getChauffeurs(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/tracking/chauffeur`,
      data
    );
  }

  getCoursiers(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/pod/coursier`,
      data
    );
  }

  addManifest(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/manifest/add`,
      data
    );
  }

  getManifest(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/manifest/get`,
      data
    );
  }

  getContacts(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/ops/contacts`, data);
  }

  addContact(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/user/contact/create`,
      data
    );
  }

  allOpsClients(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/clients`,
      data
    );
  }

  allBillingCenterByOps(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/billings-centers`,
      data
    );
  }

  updateShippingType(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/package/update`,
      data
    );
  }

  sendMailOrSms(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/shipping/sms/add`,
      data
    );
  }

  allEmployes(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/admin-ops-chauffeur/list`,
      data
    );
  }

  allUsername(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/users/by-customer-billing`,
      data
    );
  }
}

export default new OpsService();
