import axios from "axios";
import TokenService from "../config/tokenService.js";

class EmployeesService {

    constructor() {
        TokenService.setHeader();
    }
    getList(params = {}) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/salaries`, params)
    }

    create(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/salarie/create`, data);
    }

    update(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/salarie/update`, data);
    }

}

const employeesService = new EmployeesService();

export default employeesService