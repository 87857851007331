import Joi from "joi";

export const validateTrackingData = (body) => {
  const schema = Joi.object({
    details: Joi.string().required().label("Description"),
    heur_suivi: Joi.string().required().label("Heure de suivi"),
    date_suivi: Joi.string().required().label("Date de suivi"),
    agents: Joi.string().required().label("Agent"),
    chaufeur: Joi.string().required().label("Chauffeur"),
    shipping_id: Joi.number().required().label("Shipping"),
    isparis: Joi.number().required().label("Interface Paris"),
    ismedical: Joi.number().required().label("Interface Medical"),
    isservice: Joi.number().required().label("Interface Service"),
    visible: Joi.number().required().label("Visibilité"),
    problem: Joi.number().required().label("Problème"),
    mail: Joi.number().required().label("Mail"),
    sms: Joi.number().required().label("SMS"),
  })
    .messages(customMessages)
    .unknown(true);

  return returnError(schema.validate(body));
};

export const validateEmailData = (body) => {
  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    type: Joi.string().required().required().label("Type"),
    shipping_id: Joi.number().required().label("Shipping"),
  })
    .messages(customMessages)
    .unknown(true);

  return returnError(schema.validate(body));
};

export const validatePodData = (body) => {
  const schema = Joi.object({
    id: Joi.number().optional().label("ID"),
    type: Joi.string().optional().label("Type"),
    signature: Joi.string().required().label("Signature"),
    coursier: Joi.string().required().label("Coursier"),
    date: Joi.string().required().label("Date"),
    heurs: Joi.string().required().label("Heurs"),
    created_at: Joi.string().optional().label("Date de création"),
    updated_at: Joi.string().optional().label("Date de modification"),
    need_archive: Joi.number().optional().label("Besoin d'archive"),
  })
    .messages(customMessages)
    .unknown(true);

  return returnError(schema.validate(body));
};

export const validateExpeditorData = (body) => {
  const schema = Joi.object({
    fullname: Joi.string().required().label("Nom complet"),
    company: Joi.string().required().label("Nom de l'entreprise"),
    address1: Joi.string().required().label("Adresse"),
    address2: Joi.string().empty().label("Complément d'adresse"),
    city: Joi.string().required().label("Ville"),
    civility: Joi.string().required().label("Civilité"),
    country: Joi.string().required().label("Pays"),
    phone1: Joi.string().required().label("Téléphone 1"),
    phone2: Joi.string().optional().empty().label("Téléphone 2"),
    postalCode: Joi.string().pattern(/^[a-zA-Z0-9]{4,}$/).required().label("Code postal"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    phonecode: Joi.string().optional().empty().label("Code téléphonique"),
    type: Joi.string().optional().empty().label("Type"),
    created_at: Joi.string().optional().empty().label("Date de création"),
    updated_at: Joi.string().optional().empty().label("Date de modification"),
    id: Joi.number().required().label("ID"),
    shipping_id: Joi.string().required().label("ID de l'expédition"),
  })
    .messages(customMessages)
    .unknown(true);

  return returnError(schema.validate(body));
};

export const validateShippingDetails = (body) => {
  const shippingSchema = Joi.object({
    pickup_date: Joi.string().required().label("Date d'enlèvement"),
    delivery_date: Joi.string().required().label("Date de livraison"),
    pickup_time: Joi.string().required().label("Heure d'enlèvement"),
    delivery_time: Joi.string().required().label("Heure de livraison"),
    code_donneur: Joi.string().allow("").optional().label("Code donneur"),
    code_receveur: Joi.string().allow("").optional().label("Code receveur"),
    customer_ref: Joi.string().allow("").optional().label("Référence"),
    pickup_instruction: Joi.string().allow("").optional().label("Instruction"),
    delivery_instruction: Joi.string()
      .allow("")
      .optional()
      .label("Instruction de livraison"),
    comment: Joi.string().allow("").optional().label("Commentaire"),
    tracking_mail: Joi.string()
      .email({ tlds: { allow: false } })
      .allow("")
      .optional()
      .label("Mail de suivi"),
    transport_type: Joi.string()
      .allow("")
      .optional()
      .label("Type de transport"),
  })
    .messages(customMessages)
    .unknown(true);

  const packageSchema = Joi.object({
    id: Joi.number(),
    width: Joi.number().optional().label("Largeur"),
    length: Joi.number().optional().label("Longueur"),
    height: Joi.number().optional().label("Hauteur"),
    weight: Joi.number().optional().label("Poids"),
    description: Joi.string().allow("").optional().label("Description"),
    value_in_douane: Joi.string().allow("").optional().label("Valeur en douane"),
    statut: Joi.any().optional().allow("").label("Statut"),
    montant_devi: Joi.any().allow("").optional().label("Montant devi"),
    nombre_devi: Joi.any().allow("").optional().label("Nombre devi"),
    created_at: Joi.string().optional().allow("").label("Date de création"),
    updated_at: Joi.string().optional().allow("").label("Date de modification"),
    shipping_id: Joi.number().optional().allow("").label("ID de l'expédition"),

  }).messages(customMessages)
    .unknown(true) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });


  const courseSchema = Joi.object({
    package_description: Joi.string().required().label("Description du colis"),
    additional_insurance_amount: Joi.any().optional().label("Montant de l'assurance"),
    valeur_douane: Joi.string().allow("").optional().label("Valeur en douane"),
    porteur: Joi.string().allow("").optional().label("Porteur"),
  }).messages(customMessages)
    .unknown(true) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });

  const schema = Joi.object({
    shipping_id: Joi.any().optional().allow("").label("ID de l'expédition"),
    shipping: shippingSchema.required(),
    package: body.isExpedition
      ? packageSchema.required()
      : Joi.any().optional(),
    course: !body.isExpedition
      ? courseSchema.required().messages({
        "any.required": "Les informations de la course sont obligatoires",
      })
      : Joi.alternatives().try(courseSchema, null).optional(),
    /* package: Joi.alternatives().try(packageSchema, null).optional(),
        course: Joi.alternatives().try(courseSchema, null).optional(), */
    info_supplementaire: Joi.any().optional().allow("").label("Informations supplémentaires"),
    type_expedition: Joi.string().allow("").optional().allow("").label("Informations supplémentaires"),
  }).messages(customMessages)
    .unknown(true) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });

  return returnError(schema.validate(body));
};

export const validateFactureLineData = (body) => {
  const schema = Joi.object({
    id: Joi.number().required().label("Identifiant"),
    line_type: Joi.string().valid("Facture", "Avoir").required().label("Type de ligne"),
    price: Joi.number().required().label("Prix"),
    quantity: Joi.number().required().label("Quantité"),
    comment: Joi.string().allow("").label("Commentaire"),
  }).messages(customMessages)
    .unknown(true) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });

  return returnError(schema.validate(body));
};

export const validateCarData = (body) => {
  const schema = Joi.object({
    id: Joi.number().optional().allow("").label("Identifiant"),
    plaque: Joi.string().required().label("Plaque"),
    kmdepart: Joi.number().required().label("Km de départ"),
  }).messages(customMessages)
    .unknown(true) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });

  return returnError(schema.validate(body));
};

export const validateFacturationCodeData = (body) => {
  const schema = Joi.object({
    label: Joi.string().required().label("Libellé"),
    description: Joi.string().required().label("Description"),
    montant: Joi.number().optional().label("Montant"),
    variation: Joi.number().optional().label("Variation"),
    fuel: Joi.number().optional().label("Fuel"),
    for_variation: Joi.number().optional().label("Variation"),
    Europe: Joi.number().optional().label("Europe"),
    Express: Joi.number().optional().label("Express"),
    International: Joi.number().optional().label("International"),
    Locale: Joi.number().optional().label("Locale"),
    Spx_1h30: Joi.number().optional().label("Spx 1h30"),
    SPX_3h: Joi.number().optional().label("SPX 3h"),
    National: Joi.number().optional().label("National"),
    Premium: Joi.number().optional().label("Premium"),
    Spx: Joi.number().optional().label("Spx"),
  }).messages(customMessages)
    .unknown(true) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });

  return returnError(schema.validate(body));
};

export const validateEmployeeData = (body) => {
  const schema = Joi.object({
    id: Joi.number().optional().label("Identifiant"),
    nom: Joi.string().required().label("Nom"),
    prenom: Joi.string().required().label("Prénom"),
    telephone: Joi.string().required().label("Téléphone"),
    email: Joi.string().email({ tlds: { allow: false } }).required().label("Email"),
    adress: Joi.string().required().label("Adresse"),
    postal_code: Joi.string().pattern(/^[a-zA-Z0-9]{4,}$/).required().label("Code postal"),
    city: Joi.string().required().label("Ville"),
    country: Joi.string().required().label("Pays"),
  }).messages(customMessages)
    .unknown(true) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });

  return returnError(schema.validate(body));
};

export const validateFuelData = (body) => {
  const schema = Joi.object({
    id: Joi.number().optional().label("Identifiant"),
    value: Joi.number().required().label("Valeur"),
  }).messages(customMessages)
    .unknown(true) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });

  return returnError(schema.validate(body));

};

export const validateUserData = (body) => {
  const schema = Joi.object({
    id: Joi.number().optional().label("Identifiant"),
    name: Joi.string().required().label("Nom"),
    email: Joi.string().email({ tlds: { allow: false } }).required().label('Email'),
    telephone1: Joi.string().required().label("Téléphone"),
    address1: Joi.string().required().label("Adresse"),
    postal_code: Joi.string()
      .pattern(/^[a-zA-Z0-9]{4,}$/)
      .min(4)
      .required()
      .label("Code postale"),
    city: Joi.string().required().label("Ville"),
    country: Joi.string().required().label("Pays"),
    customer_id: Joi.number().required().label("Client"),
    bill_center_id: Joi.array().required().label("Centre de facturation"),

  }).messages(customMessages)
    .unknown(true) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });

  return returnError(schema.validate(body));
};

export const validateActorData = (body) => {
  const schema = Joi.object({
    id: Joi.number().optional().label("Identifiant"),
    name: Joi.string().required().label("Nom"),
    postal_code: Joi.string()
      .pattern(/^[a-zA-Z0-9]{4,}$/)
      .min(4)
      .required()
      .label("Code postale"),
    email: Joi.string().email({ tlds: { allow: false } }).required().label("Email"),
    telephone1: Joi.string().required().label("Téléphone"),
    address1: Joi.string().required().label("Adresse"),
  });

  const { error } = schema.validate(body, { allowUnknown: true });
  if (error) {
    return { valid: false, error: error.details[0].message };
  }
  return { valid: true };
};

export const validateFactureData = (body) => {
  const schema = Joi.object({
    type: Joi.string().required().label("Type"),
    date_creation: Joi.string().required().label("Date de création"),
    date_echeance: Joi.string().required().label("Date d'échéance"),
    shippingIds: Joi.array().required().label("ID des expéditions"),
    paymentMethod: Joi.string().required().label("Mode de règlement"),
    facture_reedite: Joi.when("type", {
      is: "reediter", // La valeur spécifique de field1 pour laquelle field2 devient obligatoire
      then: Joi.string().required().label('Rééditer'), // field2 est obligatoire si field1 est "reediter"
      otherwise: Joi.any().label('Rééditer'), // field2 est obligatoire si field1 est "reediter" // Autrement, field2 n'est pas obligatoire
    })


  }).messages(customMessages)
    .unknown(true) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });

  return returnError(schema.validate(body));
};

export const validateFactureSave = (body) => {
  const schema = Joi.object({
    type: Joi.string().required().label("Type"),
    shipping_ids: Joi.array().required().label("ID des expéditions"),
    montant: Joi.number().optional().allow("").label("Montant"),
    fuel: Joi.number().optional().allow("").label("Fuel"),
    tva: Joi.number().optional().allow("").label("TVA"),
    total: Joi.number().optional().allow("").label("Total"),
  }).messages(customMessages)
    .unknown(true) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });

  return returnError(schema.validate(body));
};

export const isStrongPassword = (password) => {
  // Check if password has at least 8 characters
  const hasMinimumLength = password.length >= 8;

  // Check if password has at least one lowercase letter
  const hasLowercase = /[a-z]/.test(password);

  // Check if password has at least one uppercase letter
  const hasUppercase = /[A-Z]/.test(password);

  // Check if password has at least one special character
  const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

  if (!hasMinimumLength) {
    return {
      valid: false,
      error: "Mot de passe trop court",
    };
  } else if (!hasLowercase) {
    return {
      valid: false,
      error: "Doit contenir au moins une lettre en minuscule.",
    };
  } else if (!hasUppercase) {
    return {
      valid: false,
      error: "Doit contenir au moins une lettre en majuscule.",
    };
  } else if (!hasSpecialChar) {
    return {
      valid: false,
      error: "Doit contenir au moins un caractère spécial",
    };
  } else {
    return {
      valid: true,
      error: null,
    };
  }
};

export const calculateValidator = (body, require) => {
  let schema;

  if (require) {
    schema = Joi.object({
      expedition_mode: Joi.string().required().label("Mode d'expédition"),
      package_destination: Joi.number().required().label("Destination du colis"),
      package_length: Joi.number().required().label("Longueur du colis"),
      package_width: Joi.number().required().label("Largeur du colis"),
      package_height: Joi.number().required().label("Hauteur du colis"),
      package_value: Joi.number().required().label("Valeur du colis"),
      package_weight: Joi.number().required().label("Poids du colis"),
    }).messages(customMessages)
      .unknown(true) // Autoriser les champs supplémentaires
      .messages({
        "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
      });

  } else {
    schema = Joi.object({
      expedition_mode: Joi.string().required().label("Mode d'expédition"),
      package_destination: Joi.number().required().label("Destination du colis"),
      package_length: Joi.number().required().label("Longueur du colis"),
      package_width: Joi.number().required().label("Largeur du colis"),
      package_height: Joi.number().required().label("Hauteur du colis"),
      package_weight: Joi.number().required().label("Poids du colis"),
    }).messages(customMessages)
      .unknown(true) // Autoriser les champs supplémentaires
      .messages({
        "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
      });

  }

  return returnError(schema.validate(body));
};

export const validateTransportTypesData = (body) => {
  const schema = Joi.object({
    id: Joi.number().optional().empty().label("ID"),
    label: Joi.string().required().label("Libellé"),
  })
    .messages(customMessages)
    .unknown(true);

  return returnError(schema.validate(body));
};

const customMessages = {
  "string.base": "{{#label}} doit être une chaîne de caractères",
  "string.uri": "{{#label}} doit être une URL valide",
  "string.empty": "{#label} ne doit pas être vide",
  "string.email": "Veuillez fournir une adresse email valide pour {{#label}}",
  "string.required": "{{#label}} est obligatoire",
  "string.length":
    "{{#label}} doit contenir contient {{#limit}} de caractère(s)",
  "string.min": "{{#label}} doit comporter au minimum {{#limit}} caractère(s)",
  "any.required": "{{#label}} est obligatoire",
  "string.isoDate": "{{#label}} doit être une date valide au format ISO",
  "string.valid": "{{#label}} n'a pas une valeur valide",
  "boolean.base": "{{#label}} doit être un boolean",
  "number.base": "{{#label}} doit être un nombre",
  "object.base": "{{#label}} doit être un objet",
};

const returnError = (response) => {
  const { error } = response;
  if (error) {
    const firstError = error.details[0];
    const fieldName = firstError.path[0];
    const errorMessage = firstError.message;

    return {
      valid: false,
      error: true,
      errorData: { field: fieldName, message: errorMessage },
    };
  }
  return { valid: true };
};

export const InformationValidator = (data) => {
  const schema = Joi.object({
    id: Joi.any().optional().label("Identifiant"),
    with_image: Joi.object({
      label: Joi.string().required().label("Avec Image"),
      value: Joi.number().required().label("Avec Image"),
    }),
    image_url: Joi.any()
      .when("with_image.value", {
        is: 1,
        then: Joi.object().required(),
        otherwise: Joi.any().optional(),
      })
      .label("Image"),
    libele: Joi.string().required().label("Libellé"),
    contenus: Joi.string().required().label("Contenus"),
    active_baniere: Joi.object({
      label: Joi.string().required().label("Banière"),
      value: Joi.number().required().label("Banière"),
    }),
  })
    .messages(customMessages)
    .unknown(false) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });

  return returnError(schema.validate(data));
};

export const InformationUpdateValidator = (data) => {
  const schema = Joi.object({
    id: Joi.any().optional().label("Identifiant"),
    with_image: Joi.object({
      label: Joi.string().required().label("Avec Image"),
      value: Joi.number().required().label("Avec Image"),
    }),
    image_url: Joi.any()
      .when("with_image.value", {
        is: 1,
        then: Joi.any().required(),
        otherwise: Joi.any().optional(),
      })
      .label("Image"),
    libele: Joi.string().required().label("Libellé"),
    contenus: Joi.string().required().label("Contenus"),
    active_baniere: Joi.object({
      label: Joi.string().required().label("Banière"),
      value: Joi.number().required().label("Banière"),
    }),
  })
    .messages(customMessages)
    .unknown(true) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });

  return returnError(schema.validate(data));
};

export const emballageValidator = (data) => {
  const schema = Joi.object({
    temperature: Joi.string().required().label("Températeur"),
    gammeEmballage: Joi.array()
      .items(
        Joi.object({
          label: Joi.string().required().label("Gamme d'emballage"),
          value: Joi.string().required().label("Gamme d'emballage"),
        })
      )
      .required()
      .label("Gamme d'emballage"),
    sonde: Joi.boolean().required().label("Sonde de température"),
    type: Joi.any()
      .when("sonde", {
        is: true,
        then: Joi.string().required(),
        otherwise: Joi.any().optional(),
      })
      .label("Type de sonde"),
    diagnobags: Joi.boolean().required().label("Diagnóbags"),
    diagnobagsType: Joi.any()
      .when("diagnobags", {
        is: true,
        then: Joi.string().required(),
        otherwise: Joi.any().optional(),
      })
      .label("Type de diagnobags"),
  })
    .messages(customMessages)
    .unknown(false) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });

  return returnError(schema.validate(data));
};

export const emballageCreateValidator = (data) => {
  const schema = Joi.object({
    type_emballage_id: Joi.number().required().label("Type emballage"),
    nom_produit: Joi.string().required().label("Libellé"),
    dimensions_exterieures_longueur: Joi.number()
      .required()
      .label("Longueur exterieur"),
    dimensions_exterieures_largeur: Joi.number()
      .required()
      .label("Largeur exterieur"),
    dimensions_exterieures_hauteur: Joi.number()
      .required()
      .label("Hauteur exterieur"),
    dimensions_interieures_longueur: Joi.number()
      .required()
      .label("Longueur intérieur"),
    dimensions_interieures_largeur: Joi.number()
      .required()
      .label("Largeur intérieur"),
    dimensions_interieures_hauteur: Joi.number()
      .required()
      .label("Hauteur intérieur"),
    volume_utile: Joi.number().required().label("Volume utile"),
    poids: Joi.number().required().label("Poids"),
    autonomie: Joi.number().required().label("Autonomie"),
  })
    .messages(customMessages)
    .unknown(false) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });

  return returnError(schema.validate(data));
};


