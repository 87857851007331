import { create } from 'zustand'

export const BannierStore = create((set) => ({
    bannierList: [],
    bannier: {
        active_baniere: {
            label: "Afficher",
            value: true
        },
        whithImage: {
            label: "Sans",
            value: 0
        }
    },
    updateBannier: (id, newData) => set((state) => {
        const index = state.bannierList.findIndex((item) => item.id === id);


        if (index === -1) {
            // Si aucune adresse avec cet ID n'est trouvée, on renvoie l'état inchangé
            return state;
        }

        // Cloner l'objet trouvé pour ne pas muter l'état directement
        const updatedbannier = { ...state.bannierList[index], ...newData };

        // Créer une nouvelle liste d'adresses avec l'adresse mise à jour
        const updatedbannierList = [...state.bannierList];
        updatedbannierList[index] = updatedbannier;

        // Retourner le nouvel état avec la liste d'adresses mise à jour
        return { bannierList: [...updatedbannierList] };

    }),
    deleteBannier: (id) => set((state) => {
        let newBannie = state.bannierList.filter((item) => item?.id !== id)
        return { bannierList: [...newBannie] }
    }),
    setBannier: (newData) => set((state) => ({
        bannier: { ...state.bannier, ...newData }
    })),
    addBannier: (newData) => set((state) => ({
        bannierList: [...state.bannierList, newData]
    })),
    setBannierList: (newData) => set((state) => ({
        bannierList: [...newData]
    })),
    resetBannier: () => set((state) => ({
        bannier: {
            active_baniere: {
                label: "Afficher",
                value: true
            },
            whithImage: {
                label: "Sans",
                value: 0
            }
        }
    }))

}))