import { useEffect } from "react";
import "./App.css";
import routing from "./routing.jsx";
import { RouterProvider } from "react-router-dom";
import EchoContext from "./contexts/echo.jsx";


function App() {

  return (
    <div className="App h-full">
      <EchoContext>
        <RouterProvider router={routing} />
      </EchoContext>
    </div>
  );
}

export default App;
