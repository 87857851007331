// create FacturationLine react service

import axios from "axios";
import TokenService from "../config/tokenService.js";

class FacturationLineService {

    constructor() {
        TokenService.setHeader();
    }

    fetchFacturationLines(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/facturation-codes`, data);
    }

    fetchFacturationLineById(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/facturation-code`, data);
    }

    addFacturationLine(FacturationLine) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/facturation-code/create`, FacturationLine);
    }

    editFacturationLine(FacturationLine) {
        return axios.put(`${process.env.REACT_APP_API_URL}/admin/facturation-code`, FacturationLine);
    }

    getPreview(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/ops/shippings/facture/preview`, data);
    }

}

export default new FacturationLineService();
